import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { PaystubService } from 'src/app/services/paystub/paystub.service';
import { DataTableDirective } from 'angular-datatables';

declare var $: any;

@Component({
  selector: 'app-paystub',
  templateUrl: './paystub.component.html',
  styleUrls: ['./paystub.component.scss']
})
export class PaystubComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  results: any[];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject();

  payStub: any;

  constructor(private paystubService: PaystubService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true
    };
    this.loadPayStub();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  loadPayStub() {
    this.paystubService.getAllPaystubs()
      .subscribe(data => {
        let objects: any[] = data.objects;
        objects.forEach(pay => {
          pay.hours = pay.regularHours + pay.overtimeHours + pay.otherHours;
          pay.grossPay = (pay.regularRate * pay.regularHours) + (pay.overtimeRate * pay.overtimeHours) + (pay.otherRate * pay.otherHours);
          if (pay.deductions) {
            let deductionMount = 0;
            pay.deductions.forEach(deduction => {
              deductionMount += deduction.deduction;
            });
            pay.deductionMount = deductionMount;
            pay.netPay = pay.grossPay - deductionMount;
          } else {
            pay.netPay = pay.grossPay;
          }
        });
        this.results = objects;
        // Calling the DT trigger to manually render the table
        // this.dtTrigger.next();
      });
  }

  onEditPayStub(payStub) {
    this.payStub = payStub;
    $('#modal-paystub').modal('show');
  }

  onCloseDialog() {
    $('#modal-paystub').modal('hide');
  }

  updatePayStubComponentClick(clickObj: any): void {
    // const item = this.results.find(((i: any) => i.payStubId === clickObj.payStub.payStubId));
    // if (!!item) {
    //   // console.log(item);
    // }
    this.loadPayStub();
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
    $('#modal-paystub').modal('hide');
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
