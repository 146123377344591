import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { ResponseInterface } from '../../models/authorization/response-interface';
import { SERVER_URL } from '../../general/general.variables';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private urlBase: string;
  private headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(public _http: HttpClient) {
    // this.urlBase = 'http://localhost:3000/';
    this.urlBase = SERVER_URL + 'users/'; // Server
  }

  sendPasswordCode(email: string): Observable<any> {
    return this._http
      .post<ResponseInterface>(this.urlBase + 'sendPasswordCode', { email }, { headers: this.headers })
      .pipe(map(data => data));
  }

  verifyCode(code: string): Observable<any> {
    return this._http
      .post<ResponseInterface>(this.urlBase + 'verifyPasswordCode', { code }, { headers: this.headers })
      .pipe(map(data => data));
  }

  changePassword(object: Object): Observable<any> {
    return this._http
      .post<ResponseInterface>(this.urlBase + 'resetPassword', object, { headers: this.headers })
      .pipe(map(data => data));
  }

  login(userName: string, password: string): Observable<any> {
    return this._http.get<ResponseInterface>(this.urlBase, { params: { userName, password } }).pipe(map(data => data));
  }

  getMessageConfirmationCode(code: string): Observable<any> {
    return this._http
      .get<ResponseInterface>(this.urlBase + `confirmation`, { params: { code } })
      .pipe(map(data => data));
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  getTokenJson(): Object {
    const tokenString = localStorage.getItem('token');
    if (tokenString) {
      return JSON.parse(tokenString);
    } else {
      return null;
    }
  }

  getTokenString(): string {
    const tokenString = localStorage.getItem('token');
    if (tokenString) {
      return tokenString;
    } else {
      return null;
    }
  }

  setUser(user: Object): void {
    localStorage.removeItem('currentUser');
    const userString = JSON.stringify(user);
    localStorage.setItem('currentUser', userString);
  }

  getCurrentUser(): any {
    const user_string = localStorage.getItem('currentUser');
    if (!isNullOrUndefined(user_string)) {
      return JSON.parse(user_string);
    } else {
      return null;
    }
  }

  isLoggedIn(): boolean {
    return this.getCurrentUser() != null;
  }

  logout(): boolean {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    return true;
  }
}
