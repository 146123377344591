import { TrainerService } from './../../services/trainer/trainer.service';
import { AuthService } from './../../services/authorization/auth.service';
import { NoteService } from './../../services/note/note.service';
import { SERVER_URL } from './../../general/general.variables';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormGroup, Validators, FormControl } from '@angular/forms';
declare var $: any;
declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'app-contact-logs',
  templateUrl: './contact-logs.component.html',
  styleUrls: ['./contact-logs.component.scss']
})

export class ContactLogsComponent implements OnInit {

  // viewType = '1';
  myNotes: any[];
  receiveNotes: any[];
  SERVER_URL = SERVER_URL;
  userdata: any = this.authProv.getCurrentUser();

  note: any;

  contacts: Array<any> = [];
  isRecipient = false;

  canAcknowledge = false;
  canRespond = false;
  canDecline = false;

  contactMethods = [
    { label: 'Phone', id: '1' },
    { label: 'SMS', id: '2' },
    { label: 'In Person', id: '3' },
    { label: 'Note', id: '4' },
    { label: 'Email', id: '5' }
  ];

  signupform: FormGroup = new FormGroup({
    contact: new FormControl('', [
      Validators.required,
      Validators.pattern(/[^0]+/),
      Validators.min(0)
    ]),
    contactMethod: new FormControl('', [
      Validators.required,
      Validators.pattern(/[^0]+/),
      Validators.min(0)
    ]),
    note: new FormControl('', [Validators.required]),
    followupDate: new FormControl('', [Validators.required]),
    followupTime: new FormControl('', [Validators.required])
  });


  constructor(
    private authProv: AuthService,
    private noteProvider: NoteService,
    private trainerProvider: TrainerService
  ) {
  }

  ngOnInit() {
    this.loadReceivedNotes()
      .then(response => {
        this.receiveNotes = response;
        return this.loadEmitNotes();
      })
      .then(response => {
        this.myNotes = response;
        return;
      });

    this.loadContacts(this.userdata.userType);
  }

  loadReceivedNotes() {
    return new Promise<any[]>((resolve, reject) => {
      this.noteProvider
        .getNotesByUserReceives(this.userdata.userId)
        .subscribe(data => {
          resolve(data.objects.sort(this.sortNotesList));
          // this.receiveNotes = data.objects.sort(this.sortNotesList);
        });
    });
  }

  loadEmitNotes() {
    return new Promise<any[]>((resolve, reject) => {
      this.noteProvider
        .getNotesByUserEmit(this.userdata.userId)
        .subscribe(data => {
          resolve(data.objects.sort(this.sortNotesList));
          // this.myNotes = data.objects.sort(this.sortNotesList);
        });
    });
  }

  loadContacts(typeUser) {
    switch (typeUser) {
      case 0:
      case 1:
        break;
      case 2:
      case 3:
        this.trainerProvider.getAllLeadTrainers().subscribe((data: any) => {
          const objects = data.response.objects;
          objects.forEach(element => {
            const contact = {
              personId: element.personId,
              firstName: element.first_name,
              lastName: element.last_name,
              pictureProfile: element.pictureProfile,
              userId: element.userId,
              textTypeUser: 'LT'
            };
            this.contacts.push(contact);
          });

          this.loadTrainersByLeadTrainer();
        });
        this.trainerProvider.getAllAdministrators().subscribe((data: any) => {
          const objects = data.response.objects;
          objects.forEach(element => {
            const contact = {
              personId: element.personId,
              firstName: element.first_name,
              lastName: element.last_name,
              pictureProfile: element.pictureProfile,
              userId: element.userId,
              textTypeUser: 'AD'
            };
            this.contacts.push(contact);
          });
          this.loadTrainersByLeadTrainer();
        });
        break;
    }
  }

  loadTrainersByLeadTrainer() {

    this.trainerProvider
      .getTrainerListFromLeadTrainer(this.userdata.trainerId)
      .subscribe(data => {
        const objects = data.objects;
        const contacts: Array<any> = [];
        objects.forEach((element: any) => {
          const contact = {
            personId: element.personId,
            firstName: element.first_name,
            lastName: element.last_name,
            pictureProfile: element.pictureProfile,
            userId: element.userId,
            textTypeUser: 'Trainer'
          };
          contacts.push(contact);
        });
        this.contacts = this.contacts.concat(contacts);
      });
  }

  sortNotesList(a, b) {
    return new Date(b.created).getTime() - new Date(a.created).getTime();
  }

  noteStatusColor(status: number) {
    switch (status) {
      case 0:
        return 'danger';
      case 1:
        // read
        return 'danger';
      case 2:
        // acknowledge
        return 'light';
      case 3:
        // approved
        return 'success';

      case 4:
        // declined
        return 'primary';
      default:
        return 'light';
    }
  }

  notePriority(myNote) {
    const DEFAULTCOLOR = '#12191E';
    const WARNINGCOLOR = '#FFDE1D';
    const DANGERCOLOR = '#B81818';
    if (!myNote) {
      return DEFAULTCOLOR;
    }
    if (myNote.status >= 2) {
      return DEFAULTCOLOR;
    }
    const diff = this.differenceInDays(new Date(), new Date(myNote.followupDate));
    if (diff < 2 && diff >= 0) {
      return WARNINGCOLOR;
    } else if (diff < 0) {
      return DANGERCOLOR;
    }
    return DEFAULTCOLOR;
  }

  differenceInDays(d0, d1) {
    try {
      d0 = new Date(+d0);
      d1 = new Date(+d1);
      d0.setHours(12, 0, 0, 0);
      d1.setHours(12, 0, 0, 0);
      return Math.round((d1 - d0) / 8.64e7);
    } catch (ex) {
      console.error(ex);
      return 0;
    }
  }

  onSelectNote(selectNote: any) {
    this.note = selectNote;
    this.isRecipient = this.userdata.userId === this.note.userReceivesId;

    this.CleanNote();
    if (this.isRecipient && this.note.status === 0) {
      this.noteProvider
        .updateStatusNote(this.note.noteId, 1, '')
        .subscribe(data => {
          if (data.success) {
            this.note.status = 1;
          }
        });
    }
    if (this.userdata.userId === this.note.userReceivesId && this.note.status === 1) {
      this.noteProvider.updateStatusNote(this.note.noteId, 2, '').subscribe(
        data => {
          if (data.success) {
            this.note.status = 2;
          }
        },
        error => {
          this.onIsError();
        }
      );
    }
    $('#note-modal').modal('show');
  }

  onIsError(): void {
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
    }, 4000);
  }


  Respond(noteId) {
    $('#exampleModalCenter').modal('show');
    $('#exampleModalCenter').on('click', '#paramsOkay', (e) => {
      const response = $('#response').val();
      if (this.isRecipient) {
        this.noteProvider
          .updateStatusNote(noteId, 3, response)
          .subscribe(data => {
            this.Reload(noteId);
            this.ngOnInit();
            $('#exampleModalCenter').modal('hide');
          });
      }
    });
  }

  onNewNote() {
    this.initNote();
    $('#note-modal').modal('show');
  }

  initNote() {
    this.note = {
      userEmitId: this.userdata.userId,
      userReceivesId: null,
      note: null
    };
  }

  CheckActions(note) {
    switch (note.status) {
      case 0:
      case 1:
        // Created or read
        this.canAcknowledge = true;
        this.canRespond = true;
        this.canDecline = true;
        break;
      case 2:
        // acknowledge
        this.canAcknowledge = false;
        this.canRespond = true;
        this.canDecline = true;
        break;
      case 3:
        // approved
        this.canAcknowledge = false;
        this.canRespond = false;
        this.canDecline = false;
        break;
      case 4:
        // declined
        this.canAcknowledge = false;
        this.canRespond = false;
        this.canDecline = false;
        break;
      default:
        break;
    }
  }

  CleanNote() {
    this.CheckActions(this.note);
    if (this.note.followupDate) {
      this.note.followupDate = new Date(this.note.followupDate);
    }
    if (this.note.followupTime) {
      this.note.followupTime = new Date(`01-01-1970 ${this.note.followupTime}`);
    }
    if (this.note.note) {
      this.note.note = this.note.note.trim();
      // console.info(this.note.note)
    }
    if (this.note.response) {
      this.note.response = this.note.response.trim();
      // console.info(this.note.response)
    }
    if (!this.isRecipient && this.note.nameUserReceived) {
      this.note.nameUserEmit = this.note.nameUserReceived;
      this.note.pictureProfileUserEmit = this.note.pictureProfileUserReceived;
    }
  }

  Acknowledge(noteId) {
    if (this.isRecipient) {
      this.noteProvider.updateStatusNote(noteId, 2, '').subscribe(data => {
        this.Reload(noteId);
        this.ngOnInit();
      });
    }
  }

  Decline(noteId) {
    if (this.isRecipient) {
      this.noteProvider.updateStatusNote(noteId, 4, '').subscribe(data => {
        this.Reload(noteId);
        this.ngOnInit();
        $('#note-modal').modal('hide');
      });
    }
  }

  Reload(noteId) {
    this.noteProvider.getNoteById(noteId).subscribe(data => {
      if (data.success && data.objects) {
        this.note = data.objects[0];
        this.CleanNote();
      }
    });
  }


  onSaveNote() {
    this.note.userEmitId = this.userdata.userId;
    this.note.followupDate = moment(this.note.followupDate).format('YYYY-MM-DD');
    this.note.followupTime = moment(this.note.followupTime).format('YYYY-MM-DD');

    this.noteProvider.saveNotes(this.note).subscribe(
      data => {
        if (data.success) {
          setTimeout(() => {
            notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, data.objects.msg);
          }, 2000);
          $('#note-modal').modal('hide');
          this.note.noteId = data.objects.noteId;
          this.ngOnInit();
        } else {
          // console.error(data);
          setTimeout(() => {
            notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Contact log could not be created, please contact support.');
          }, 2000);
        }
      }, error => {
        console.error(error);
        setTimeout(() => {
          notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Contact log could not be created, please contact support.');
        }, 2000);
      });
  }

}
