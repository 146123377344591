import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ResponseInterface } from "../../models/authorization/response-interface";
import { SERVER_URL } from "../../general/general.variables";

import { AuthService } from "../authorization/auth.service"

@Injectable({
    providedIn: "root"
})
export class PictureService {
    private urlBase: string;
    private headers: HttpHeaders;

    constructor(public _http: HttpClient, private auth: AuthService) {
        this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
        this.urlBase = SERVER_URL + 'pictures/';
    }

    updateProfilePicture(profilePictureId: number, status: number): Observable<any> {
        return this._http.put<ResponseInterface>(this.urlBase, { profilePictureId, status }, { headers: this.headers }).pipe(map(data => data));
    }

    getPicturesProfilePersonId(personId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase, { headers: this.headers, params: { personId: personId } }).pipe(map(data => data));
    }

    getPictureProfilePersonIdStatus(personId: number, status: number): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `${personId}/${status}`, { headers: this.headers }).pipe(map(data => data));
    }
}