import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { SessionService } from '../../services/session/session.service';
import { AuthService } from '../../services/authorization/auth.service';

import { SERVER_URL } from "../../general/general.variables";

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public sessions: any[];
  public isError = false;
  public serverUrl: string;

  constructor(
    public authService: AuthService,
    private sessionService: SessionService,
    public datepipe: DatePipe) { }

  ngOnInit() {
    this.serverUrl = SERVER_URL;
    if (this.authService.getCurrentUser()) {
      let userType: number = this.authService.getCurrentUser()['userType'];
      if (userType != 1) {
        let trainerId = this.authService.getCurrentUser()['trainerId'];
        this.getSessionsByTrainer(userType, trainerId);
      } else {
        let personId = this.authService.getCurrentUser()['personId'];
        this.getSessionsByClient(personId);
      }
    }
  }

  getSessionsByClient(clientId: number) {
    let currentDate = new Date();
    let nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 10);
    let startDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    let currentDateString = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(nextDate, 'yyyy-MM-dd');
    this.sessionService.getSessionsByClientStatusDateRange(clientId, 1, startDate, endDate)
      .subscribe(
        data => {
          this.sessions = data.objects;
          this.sessions.forEach(session => {
            session.legend = session.trainer + ' (';
            session.legend += session.appointmentDate == currentDateString ? 'Today)' : this.datepipe.transform(session.appointmentDate, 'MM/dd/yyyy') + ')';
          });
        },
        error => this.onIsError());
  }

  getSessionsByTrainer(userType: number, trainerId: number) {
    let currentDate = new Date();
    let nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 10);
    let startDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    let currentDateString = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(nextDate, 'yyyy-MM-dd');
    switch (userType) {
      case 0:
        this.sessionService.getSessionsByTrainerStatusDateRange(trainerId, 1, startDate, endDate)
          .subscribe(
            data => {
              this.sessions = data.objects;
              this.sessions.forEach(session => {
                session.legend = session.client + ' (';
                session.legend += session.appointmentDate == currentDateString ? 'Today)' : this.datepipe.transform(session.appointmentDate, 'MM/dd/yyyy') + ')';
              });
            },
            error => this.onIsError());
        break;
      case 2:
        this.sessionService.getSessionsByLeadTrainerStatusDateRange(trainerId, 1, startDate, endDate)
          .subscribe(
            data => {
              this.sessions = data.objects;
              this.sessions.forEach(session => {
                session.legend = session.client + ' (';
                session.legend += session.appointmentDate == currentDateString ? 'Today)' : this.datepipe.transform(session.appointmentDate, 'MM/dd/yyyy') + ')';
              });
            },
            error => this.onIsError());
        break;
    }
  }

  onLogout(): void {
    this.authService.logout();
    // this.router.navigate(['/']);
    // this.router.navigateByUrl('/');
    window.location.href = "/";
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      //notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
      this.isError = false;
    }, 4000);
  }

}
