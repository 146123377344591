import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ResponseInterface } from "../../models/authorization/response-interface";
import { SERVER_URL } from "../../general/general.variables";

import { AuthService } from "../authorization/auth.service"

@Injectable({
    providedIn: "root"
})
export class SessionService {
    private urlBase: string;
    private headers: HttpHeaders;

    constructor(public _http: HttpClient, private auth: AuthService) {
        this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
        this.urlBase = SERVER_URL + 'sessions/';
    }

    saveSession(session: Object): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase, session, { headers: this.headers }).pipe(map(data => data));
    }

    saveSessions(sessions: []): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + 'saveAll/', sessions, { headers: this.headers }).pipe(map(data => data));
    }

    saveReschedule(reschedule: Object): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + 'reschedule/', reschedule, { headers: this.headers }).pipe(map(data => data));
    }

    getSessionsByClientStatus(clientId, status): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'client/', { headers: this.headers, params: { "clientId": clientId, "status": status } }).pipe(map(data => data));
    }

    getAllSessionsByClient(clientId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'client/', { headers: this.headers, params: { "clientId": clientId } }).pipe(map(data => data));
    }

    getSessionsByClientStatusDateRange(clientId, status, startDate, endDate): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'client/', { headers: this.headers, params: { "clientId": clientId, "status": status, "startDate": startDate, "endDate": endDate } }).pipe(map(data => data));
    }

    getAllSessionsByTrainer(trainerId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'trainer/', { headers: this.headers, params: { "trainerId": trainerId } }).pipe(map(data => data));
    }

    getSessionsByTrainerStatusDateRange(trainerId, status, startDate, endDate): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'trainer/', { headers: this.headers, params: { "trainerId": trainerId, "status": status, "startDate": startDate, "endDate": endDate } }).pipe(map(data => data));
    }

    getAllSessionsByLeadTrainer(leadTrainerId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'leadtrainer/', { headers: this.headers, params: { "leadTrainerId": leadTrainerId } }).pipe(map(data => data));
    }

    getSessionsByLeadTrainerStatusDateRange(leadTrainerId, status, startDate, endDate): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'leadtrainer/', { headers: this.headers, params: { "leadTrainerId": leadTrainerId, "status": status, "startDate": startDate, "endDate": endDate } }).pipe(map(data => data));
    }

    getSessionById(sessionId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'byId/', { headers: this.headers, params: { "sessionId": sessionId } }).pipe(map(data => data));
    }

    checkOut(rawSession): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + 'checkOut', rawSession, { headers: this.headers }).pipe(map(data => data));
    }
}