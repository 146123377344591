import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { SalaryService } from 'src/app/services/salary/salary.service';

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.scss']
})
export class SalaryComponent implements OnDestroy, OnInit {

  dtOptions: DataTables.Settings = {};
  results: any[];
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject();

  constructor(private salaryService: SalaryService) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true
    };
    this.salaryService.getAllSalaries()
      .subscribe(data => {
        let objects: any[] = data.objects;
        // objects.forEach(pay => {
        //   pay.hours = pay.regularHours + pay.overtimeHours + pay.otherHours;
        //   pay.grossPay = (pay.regularRate * pay.regularHours) + (pay.overtimeRate * pay.overtimeHours) + (pay.otherRate * pay.otherHours);
        //   if (pay.deductions) {
        //     let deductionMount = 0;
        //     pay.deductions.forEach(deduction => {
        //       deductionMount += deduction.deduction;
        //     });
        //     pay.deductionMount = deductionMount;
        //     pay.netPay = pay.grossPay - deductionMount;
        //   } else {
        //     pay.netPay = pay.grossPay;
        //   }
        // });
        this.results = objects;
        // Calling the DT trigger to manually render the table
        this.dtTrigger.next();
      });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
