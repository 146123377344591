import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PlanService } from '../../services/plan/plan.service';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'detail-plan',
  templateUrl: './detail-plan.component.html',
  styleUrls: ['./detail-plan.component.scss']
})
export class DetailPlanComponent implements OnInit {

  plan: any;

  constructor(
    private route: ActivatedRoute,
    private planService: PlanService
  ) { }

  ngOnInit() {
    this.initPlan();
    this.route.queryParams.subscribe(params => {
      let planId = params['planId'];
      if (planId) {
        this.loadPlan(planId);
      }
    });
  }

  initPlan() {
    this.plan = {};
  }

  loadPlan(planId) {
    this.planService.getPlanById(planId).subscribe(data => { this.plan = data.objects[0]; });
  }

  savePlan() {
    this.planService.savePlan(this.plan)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects.msg;
            typeMessage = 'success';
            // this.plan = null;
            // this.initPlan();
            this.plan.planId = data.objects.planId;
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError();
        }
      );
  }

  updatePlan() {
    this.planService.savePlan(this.plan)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects.msg;
            typeMessage = 'success';
            // this.plan = null;
            // this.initPlan();
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError()
        }
      );
  }

  onIsError(): void {
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
    }, 4000);
  }

}
