import { Component, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

import { AccountService } from '../../services/registration/registration.service';
import { UserService } from '../../services/user/user.service';
import { CRMService } from '../../services/crm/crm.service';
import { states } from "../../general/general.variables";
import { AuthService } from 'src/app/services/authorization/auth.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

declare var $: any;
declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnDestroy, OnInit {

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;

  dtOptions: DataTables.Settings[] = [];
  dtTrigger00: Subject<any> = new Subject();
  dtTrigger01: Subject<any> = new Subject();
  dtTrigger02: Subject<any> = new Subject();

  typeUser: number;
  elements: any[];
  CRMClients: any[];
  CRMTrainers: any[];
  clientCRM: any = {};
  public isError = false;
  notification: any;

  constructor(
    private accountService: AccountService,
    private userService: UserService,
    private crmService: CRMService,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    this.initDatatables();
    this.notification = {};
    this.typeUser = 0;
    this.loadUsers();
    // this.getCRMClients();
    // this.getCRMTrainers();
  }

  initDatatables() {
    this.dtOptions[0] = {
      pagingType: 'full_numbers'
    };
    this.dtOptions[1] = {
      pagingType: 'full_numbers'
    };
    this.dtOptions[2] = {
      pagingType: 'full_numbers'
    };
  }

  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger00.unsubscribe();
    this.dtTrigger01.unsubscribe();
    this.dtTrigger02.unsubscribe();
  }

  onChange(typeUser: number) {
    this.typeUser = typeUser;
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: any) => {
        if (dtInstance.table().node().id == 'first-table') {
          dtInstance.destroy();
          this.accountService.getPersonsByTypeUser(this.typeUser)
            .subscribe(
              data => {
                this.elements = data.response.objects;
                this.dtTrigger00.next();
              }, error => this.onIsError());
        }
      });
    });
  }

  loadUsers() {
    this.accountService.getPersonsByTypeUser(this.typeUser)
      .subscribe(
        data => {
          this.elements = data.response.objects;
          this.dtTrigger00.next();
        }, error => this.onIsError());
  }

  getCRMClients() {
    this.crmService.getClientsUnregistered()
      .subscribe(
        data => {
          this.CRMClients = data.objects;
          this.dtTrigger01.next();
        }, error => this.onIsError());
  }

  getCRMTrainers() {
    this.crmService.getTrainers()
      .subscribe(
        data => {
          this.CRMTrainers = data;
          this.dtTrigger02.next();
        }, error => this.onIsError());
  }

  updateStatus(user: any) {
    if (user.userType == 0 && !user.salaryId) {
      notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, 'The selected trainer needs a salary. Please going to details for add salary.');
      return;
    }
    if (user.userType == 1 && !user.clientTrainerId) {
      notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, 'The selected client needs a trainer. Please going to details for assigned trainer.');
      return;
    }
    if (user.userType == 1 && !user.clientPlanId) {
      notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, 'The selected client needs a plan. Please going to manage plans for add plan to client.');
      return;
    }
    this.userService.updateUserStatus(user.userId, 1)
      .subscribe(
        data => {
          let object = data.response;
          let msg: string;
          if (object.success) {
            msg = object.objects[0].msg;
            this.loadUsers();
          } else {
            msg = 'Ooops, On error has ocurred!';
          }
          notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, msg);
        }, error => this.onIsError());
  }

  emailConfirmation(clientCRM: any) {
    if (this.typeUser == -2) {
      let name = clientCRM.name.split(' ');
      this.clientCRM.first_name = name[0];
      this.clientCRM.last_name = name[1];
      this.clientCRM.city = clientCRM.state;
      this.clientCRM.zip = null;
      this.clientCRM.phone = clientCRM.cell.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
      this.clientCRM.email = clientCRM.email;
      this.clientCRM.typeUser = 0;
      this.clientCRM.gender = null;
      this.clientCRM.state = clientCRM.state;
      this.clientCRM.emailCRM = this.clientCRM.email;
    } else {
      this.clientCRM = clientCRM;
      this.clientCRM.typeUser = 1;
      this.clientCRM.gender = this.clientCRM.gender == 'Male' ? '0' : '1';
      this.clientCRM.emailCRM = this.clientCRM.email;
    }
    $('#modal-emailConfirmation').modal('show');
  }

  sendCode() {
    this.clientCRM.dob = null;
    let state = states.filter(element => { if (element.name == this.clientCRM.state) return element; });
    this.clientCRM.state = state[0].abbreviation;
    this.accountService.sendCodeInvitation(this.clientCRM)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.message;
            typeMessage = 'success';
            this.clientCRM = {};
            if (this.typeUser == -1) {
              this.crmService.getClientsUnregistered().subscribe(data => { this.elements = data.objects; }, error => this.onIsError());
            }
            $('#modal-emailConfirmation').modal('hide');
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        }, error => this.onIsError()
      );
  }

  goToAssignPlan(clientCRM: any) {
    this.accountService.getRegistrationByEmailCRM(clientCRM.email)
      .subscribe(
        data => {
          let registration: any[] = data.objects;
          if (registration.length > 0) {
            this.router.navigate(['/clientplan'], { queryParams: { clientId: registration[0].personId } });
          } else {
            notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, "The selected client doesn't registered.");
          }
        }
        , error => this.onIsError()
      );
  }

  viewModalNotification(user) {
    this.clientCRM = user;
    this.notification = null;
    this.notification = {};
    this.notification.recipient = user.userId;
    this.notification.sender = this.authService.getCurrentUser()['userId'];
    this.notification.ioId = null;
    this.notification.data = null;
    this.notification.icon = null;
    $('#modal-notification').modal('show');
  }

  onSendNotification() {
    this.notificationService.sendNotification(this.notification)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.message;
            typeMessage = 'success';
            this.clientCRM = {};
            this.notification = null;
            this.notification = {};
            $('#modal-notification').modal('hide');
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        }, error => this.onIsError()
      );
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => { this.isError = false; }, 4000);
  }

}
