import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MovingDirection, WizardComponent } from 'angular-archwizard';

import { AuthService } from '../../services/authorization/auth.service';
import { PlanService } from '../../services/plan/plan.service';
import { AccountService } from '../../services/registration/registration.service';
import { UserService } from '../../services/user/user.service';

import { SERVER_URL } from '../../general/general.variables';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'client-plan',
  templateUrl: './client-plan.component.html',
  styleUrls: ['./client-plan.component.scss']
})
export class ClientPlanComponent implements OnInit, OnDestroy {
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  stepIndex = 1;
  client: any;
  plan: any;
  clientPlan: any = {};
  serverUrl: string;
  personId;
  preplan;
  //
  page1: boolean;
  page2: boolean;
  page3: boolean;
  page4: boolean;
  page5: boolean;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private planService: PlanService,
    private datepipe: DatePipe,
    private accountService: AccountService,
    private userService: UserService
  ) {
    this.personId = this.route.snapshot.queryParamMap.get('personId');
    this.loadClient(this.personId, true);

    this.page1 = false;
    this.page2 = false;
    this.page3 = false;
    this.page4 = false;
    this.page5 = false;
  }

  ngOnInit() {
    this.serverUrl = SERVER_URL;
  }

  ngOnDestroy(): void {}
  presetData() {
    if (this.client.assignedTrainerId) {
      this.page1 = true;
      this.wizard.navigation.goToNextStep();
    }
    if (this.client.clientPlanId) {
      this.page2 = true;
      this.page3 = true;
      this.planService
        .getPlansByClient(this.client.personId)
        .subscribe(data => {
          this.plan = data.objects[0];
        });
    }
  }
  loadClient(clientId, firstTry) {
    this.accountService.getPerson(clientId).subscribe(data => {
      this.client = data.response.objects[0];
      if (firstTry) {
        this.presetData();
      }
    });
  }

  loadClientPlan() {
    if (this.client) {
      this.planService
        .getClientPlanById(this.client.clientPlanId)
        .subscribe(data => {});
    }
  }

  trainerSelected(trainer) {
    this.loadClient(this.personId, false);
    this.page1 = true;
  }
  planSelected(plan) {
    if (plan) {
      this.plan = plan.plan;
      this.page2 = true;
    }
  }
  paymentSelected(info) {
    this.page4 = true;

    this.userService.updateUserStatus(this.client.userId, 1)
    .subscribe(
      data => {
        const object = data.response;
        let msg: string;
        if (object.success) {
          msg = object.objects[0].msg;
        } else {
          msg = 'Ooops, On error has ocurred!';
        }
        notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, msg);
      }, error => this.onIsError());

  }
  onIsError(): void {
    setTimeout(() => {
      notify(
        null,
        'danger',
        'bounceInRight',
        'bounceOutRight',
        null,
        'Ooops, On error has ocurred!'
      );
    }, 4000);
  }
}
