import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/authorization/auth.service';
import { TrainerService } from '../../services/trainer/trainer.service';
import { LeadTrainerService } from '../../services/leadtrainer/leadtrainer.service';
import { AccountService } from '../../services/registration/registration.service';
import { MeasurementService } from '../../services/measurement/mesurement.service';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss']
})
export class MeasurementsComponent implements OnInit {

  clients: [];
  userType: number;
  trainerId: number;
  measurements: [];
  headers: any[];

  public isError = false;

  constructor(
    public measurementService: MeasurementService,
    public authService: AuthService,
    private accountService: AccountService,
    private trainerService: TrainerService,
    private leadTrainerService: LeadTrainerService) { }

  ngOnInit() {
    this.userType = this.authService.getCurrentUser()['userType'];
    this.trainerId = this.authService.getCurrentUser()['trainerId'];
    this.getClients();
    this.getMeasurements();
  }

  getMeasurements() {
    this.measurementService.getGetAllMeasurements()
      .subscribe(
        data => {
          let objects = data.objects;
          let headers = ['Date'];
          objects.forEach(element => {
            headers.push(element.name);
          });
          this.headers = headers;
        },
        error => {
          this.onIsError();
        });
  }

  getClients() {
    switch (this.userType) {
      case 0:
        this.trainerService.getClientsTrainer(this.trainerId, 1).subscribe(data => { this.clients = data.objects; }, error => this.onIsError());
        break;
      case 2:
        this.leadTrainerService.getClientsLeadTrainerStatus(this.trainerId, 1).subscribe(data => { this.clients = data.objects; }, error => this.onIsError());
        break;
      case 3:
        this.accountService.getPersonsByTypeUser(1).subscribe(data => { this.clients = data.response.objects; }, error => this.onIsError());
        break;
    }
  }

  selectClient(clientId) {
    this.getMeasurments(clientId);
  }

  getMeasurments(clientId) {
    this.measurementService.getMeasurementsByClient(clientId)
      .subscribe(
        data => {
          this.measurements = data.objects;
        },
        error => {
          this.onIsError();
        }
      );
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
      this.isError = false;
    }, 4000);
  }

}
