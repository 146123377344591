import { Component, OnInit } from '@angular/core';

import { NoteService } from '../../services/note/note.service';
import { AuthService } from '../../services/authorization/auth.service';
import { AccountService } from '../../services/registration/registration.service';
import { TrainerService } from '../../services/trainer/trainer.service';
import { LeadTrainerService } from '../../services/leadtrainer/leadtrainer.service';

declare var $: any;
declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  notes: any[];
  userId: number;
  note: any;
  personId: number;
  contacts: Array<any> = [];

  constructor(
    private authService: AuthService,
    private noteService: NoteService,
    private accountService: AccountService,
    private trainerService: TrainerService,
    private leadTrainerService: LeadTrainerService
  ) { }

  ngOnInit() {
    this.userId = this.authService.getCurrentUser()['userId'];
    this.personId = this.authService.getCurrentUser()['personId'];
    this.loadReceivedNotes();
    let typeUser = this.authService.getCurrentUser()['userType'];
    let trainerId: number = null;
    if (typeUser != 1) {
      trainerId = this.authService.getCurrentUser()['trainerId'];
    }
    this.loadContacts(typeUser, trainerId);
    this.initNote();
  }

  loadReceivedNotes() {
    this.noteService.getNotesByUserReceives(this.userId).subscribe(data => { this.notes = data.objects; this.loadEmitNotes(); });
  }

  loadEmitNotes() {
    this.noteService.getNotesByUserEmit(this.userId).subscribe(data => { this.notes = this.notes.concat(data.objects); });
  }

  initNote() {
    this.note = {
      userEmitId: this.userId,
      userReceivesId: null,
      note: null
    }
  }

  onNewNote() {
    this.initNote();
    $('#note-modal').modal('show');
  }

  loadContacts(typeUser, trainerId) {
    switch (typeUser) {
      case 0:
        this.trainerService.getClientsTrainer(trainerId, 1)
          .subscribe(
            data => {
              let objects = data.objects;
              objects.forEach(element => {
                let contact = {
                  personId: element.personId,
                  firstName: element.first_name,
                  lastName: element.last_name,
                  pictureProfile: element.pictureProfile,
                  userId: element.userId,
                  textTypeUser: 'Client'
                };
                this.contacts.push(contact);
              });
            });
        break;
      case 1:
        this.accountService.getLeadTrainerByClient(this.personId)
          .subscribe(
            data => {
              let object = data.objects;
              let contact = {
                personId: object.personId,
                firstName: object.first_name,
                lastName: object.last_name,
                pictureProfile: object.pictureProfile,
                userId: object.userId,
                textTypeUser: 'Lead Trainer'
              }
              this.contacts.push(contact);
            });
        break;
      case 2:
        this.leadTrainerService.getClientsLeadTrainerStatus(trainerId, 1)
          .subscribe(
            data => {
              let objects = data.objects;
              objects.forEach(element => {
                let contact = {
                  personId: element.personId,
                  firstName: element.first_name,
                  lastName: element.last_name,
                  pictureProfile: element.pictureProfile,
                  userId: element.userId,
                  textTypeUser: 'Client'
                };
                this.contacts.push(contact);
              });
              this.loadTrainersByLeadTrainer(trainerId);
            });
        break;
      case 3:
        this.accountService.getPersonsByTypeUser(1)
          .subscribe(
            data => {
              let objects = data.response.objects;
              objects.forEach(element => {
                let contact = {
                  personId: element.personId,
                  firstName: element.first_name,
                  lastName: element.last_name,
                  pictureProfile: element.pictureProfile,
                  userId: element.userId,
                  textTypeUser: 'Client'
                };
                this.contacts.push(contact);
              });
            });
        break;
    }
  }

  loadTrainersByLeadTrainer(leadTrainerId) {
    this.leadTrainerService.getTrainersLeadTrainerStatus(leadTrainerId, 1).
      subscribe(
        data => {
          let objects = data.objects;
          let contacts: Array<any> = [];
          objects.forEach(element => {
            let contact = {
              personId: element.personId,
              firstName: element.first_name,
              lastName: element.last_name,
              pictureProfile: element.pictureProfile,
              userId: element.userId,
              textTypeUser: 'Trainer'
            };
            contacts.push(contact);
          });
          this.contacts = this.contacts.concat(contacts);
        }
      );
  }

  onSelectNote(selectNote: any) {
    this.note = selectNote;
    if (this.userId == this.note.userReceivesId && this.note.status == 1) {
      this.noteService.updateStatusNote(this.note.noteId, 2, '').subscribe(
        data => {
          if (data.success) {
            this.note.status = 2;
          }
        },
        error => {
          this.onIsError();
        }
      );
    }
    $('#note-modal').modal('show');
  }

  onSaveNote() {
    this.noteService.saveNotes(this.note)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects.msg;
            typeMessage = 'success';
            $('#note-modal').modal('hide');
            this.note = null;
            this.initNote();
            this.loadReceivedNotes();
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError();
        }
      );
  }

  onUpdateNote() {
    this.noteService.updateNote(this.note.noteId, this.note.note)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects.msg;
            typeMessage = 'success';
            $('#note-modal').modal('hide');
            this.note = null;
            this.initNote();
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError()
        }
      );
  }

  onIsError(): void {
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
    }, 4000);
  }

}
