import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/empty';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';

@Injectable({
    providedIn: 'root'
})
export class UnauthorizedInterceptor implements HttpInterceptor {

    constructor(private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).catch((err: any) => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
                this.router.navigateByUrl('/unauthorized', { replaceUrl: true });
                // this response is handled
                // stop the chain of handlers by returning empty
                return Observable.empty();
            }

            // rethrow so other error handlers may pick this up
            return Observable.throw(err);
        });
    }
}

