import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ResponseInterface } from "../../models/authorization/response-interface";
import { SERVER_URL } from "../../general/general.variables";

import { AuthService } from "../authorization/auth.service"

@Injectable({
    providedIn: "root"
})
export class AccountService {
    private urlBase: string;
    private headers: HttpHeaders;
    private hd: HttpHeaders;

    constructor(public _http: HttpClient, private auth: AuthService) {
        this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
        this.hd = new HttpHeaders({ "Content-Type": "application/json" });
        this.urlBase = SERVER_URL + 'persons/';
    }

    getAllGoalsByClient(clientId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'goals/', { headers: this.headers, params: { 'personId': clientId } }).pipe(map(data => data));
    }

    getGoalsByClientStatus(clientId, status): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `goals/`, { headers: this.headers, params: { 'personId': clientId, 'status': status } }).pipe(map(data => data));
    }

    getLeadTrainerByClient(clientId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'leadtrainer/', { headers: this.headers, params: { 'clientId': clientId } }).pipe(map(data => data));
    }

    getPerson(personId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'byId/', { headers: this.headers, params: { "personId": personId } }).pipe(map(data => data));
    }

    getPersonsByTypeUser(typeUser): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase, { headers: this.headers, params: { 'typeUser': typeUser } }).pipe(map(data => data));
    }

    savePerson(person: Object): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + 'save', person, { headers: this.hd }).pipe(map(data => data));
    }

    sendCodeInvitation(clientCRM: Object): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + 'preregistration', clientCRM, { headers: this.headers }).pipe(map(data => data));
    }

    getPreRegistrationByInvitationCode(invitationCode): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'preregistration', { headers: this.hd, params: { 'invitationCode': invitationCode } }).pipe(map(data => data));
    }

    saveRegistration(person: Object): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + 'registration', person, { headers: this.hd }).pipe(map(data => data));
    }

    getRegistrationByEmailCRM(emailCRM): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'registration', { headers: this.headers, params: { "emailCRM": emailCRM } }).pipe(map(data => data));
    }
}