import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ResponseInterface } from "../../models/authorization/response-interface";
import { SERVER_URL } from "../../general/general.variables";

import { AuthService } from "../authorization/auth.service"

@Injectable({
    providedIn: "root"
})
export class MeasurementService {
    private urlBase: string;
    private headers: HttpHeaders;

    constructor(public _http: HttpClient, private auth: AuthService) {
        this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
        this.urlBase = SERVER_URL + 'measurements/';
    }

    saveMeasurements(measurement: Object): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + 'client/', measurement, { headers: this.headers }).pipe(map(data => data));
    }

    getGetAllMeasurements(): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase , { headers: this.headers }).pipe(map(data => data));
    }

    getMeasurementsByClient(clientId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'client/', { headers: this.headers, params: {"clientId": clientId} }).pipe(map(data => data));
    }

    getMeasurementsByClientDateRange(clientId, startDate, endDate): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'client/', { headers: this.headers, params: {"clientId": clientId, "startDate": startDate, "endDate": endDate} }).pipe(map(data => data));
    }
}