import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseInterface } from '../../models/authorization/response-interface';
import { SERVER_URL } from '../../general/general.variables';

import { AuthService } from '../authorization/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private urlBase: string;
  private headers: HttpHeaders;

  constructor(public _http: HttpClient, private auth: AuthService) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth.getTokenString()}`
    });
    this.urlBase = SERVER_URL + 'payments/';
  }

  creatCustumerCreditCard(costumer: Object): Observable<any> {
    return this._http
      .post<ResponseInterface>(
        this.urlBase + 'createCustomerAndCard',
        costumer,
        { headers: this.headers }
      )
      .pipe(map(data => data));
  }

  getCustumerCreditCard(clientId): Observable<any> {
      return this._http
      .get<ResponseInterface>(this.urlBase + '/client/cards', {
        headers: this.headers,
        params: { clientId }
      })
      .pipe(map(data => data));
  }

  chargesByClient(clientId): Observable<any> {
    return this._http
      .get<ResponseInterface>(this.urlBase + 'charges/stripe', {
        headers: this.headers,
        params: { clientId }
      })
      .pipe(map(data => data));
  }

  creatRefundToCostumer(charge: Object): Observable<any> {
    return this._http
      .post<ResponseInterface>(this.urlBase + 'refund', charge, {
        headers: this.headers
      })
      .pipe(map(data => data));
  }
}
