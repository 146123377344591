import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaystubService } from 'src/app/services/paystub/paystub.service';
import { DatePipe } from '@angular/common';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'detail-paystub',
  templateUrl: './detail-paystub.component.html',
  styleUrls: ['./detail-paystub.component.scss']
})
export class DetailPaystubComponent implements OnInit {

  payStubId;
  addDeduction: boolean = false;
  deduction: any;

  @Input() payStub: any;

  @Output() detailClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() clickBack: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private route: ActivatedRoute,
    private paystubService: PaystubService,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.payStubId = this.route.snapshot.queryParamMap.get('payStubId');
    if (this.payStubId) {
      this.paystubService.getPayStubById(this.payStubId)
        .subscribe(
          data => {
            let pay = data.objects[0];
            if (pay) {
              pay.hours = pay.regularHours + pay.overtimeHours + pay.otherHours;
              pay.grossPay = (pay.regularRate * pay.regularHours) + (pay.overtimeRate * pay.overtimeHours) + (pay.otherRate * pay.otherHours);
              if (pay.deductions) {
                let deductionMount = 0;
                pay.deductions.forEach(deduction => {
                  deductionMount += deduction.deduction;
                });
                pay.deductionMount = deductionMount;
                pay.netPay = pay.grossPay - deductionMount;
              } else {
                pay.netPay = pay.grossPay;
              }
            }
            this.payStub = pay;
          }
        );
    }
  }

  onAddDeduction() {
    this.addDeduction = true;
    this.deduction = {};
  }

  onCancelDeduction() {
    this.addDeduction = false;
    this.deduction = null;
  }

  onUpdatePayStub() {
    this.payStub.payDate = this.datepipe.transform(this.payStub.payDate, 'yyyy-MM-dd');
    this.paystubService.updatePayStub(this.payStub)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = 'Update Paystub successfully.';
            typeMessage = 'success';
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          if (this.detailClick) {
            this.detailClick.emit({ success: data.success, payStub: this.payStub });
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError();
        }
      );
  }

  onSaveDeduction() {
    this.deduction.payStubId = this.payStub.payStubId;
    this.paystubService.addDeduction(this.deduction)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = 'Add Deduction successfully.';
            typeMessage = 'success';
            let deductionMount = this.payStub.deductionMount + this.deduction.deduction;
            this.payStub.deductionMount = deductionMount;
            this.payStub.netPay = this.payStub.grossPay - deductionMount;
            this.payStub.needUpdate = true;
            if (!this.payStub.deductions) {
              this.payStub.deductions = [];
            }
            this.payStub.deductions.push(this.deduction);
            this.deduction = null;
            this.addDeduction = false;
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError();
        }
      );
  }

  onGoBack() {
    this.clickBack.emit();
    this.addDeduction = false;
    this.deduction = null;
  }

  onIsError(): void {
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
    }, 4000);
  }

}
