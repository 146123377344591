import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output
} from '@angular/core';

import { PaymentService } from '../../../services/payment/payment.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SERVER_URL } from '../../../general/general.variables';
import { Subject } from 'rxjs';

import {
  StripeService,
  Elements,
  Element as StripeElement,
  ElementsOptions
} from 'ngx-stripe';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'client-plan-payment',
  templateUrl: './client-plan-payment.component.html',
  styleUrls: ['./client-plan-payment.component.scss']
})
export class ClientPlanPaymentComponent implements OnInit, OnDestroy {
  elements: Elements;
  card: StripeElement;

  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  stripeTest: FormGroup;

  clientPlan: any = {};
  plan: any;
  client: any;
  tempCardInformation: string;
  invalidError: any;
  paymentMethods: any[] = [
    { methodId: 1, method: 'card', methodText: 'Credit Card' },
    {
      methodId: 2,
      method: 'ach_credit_transfer',
      methodText: 'ACH Credit Transfer'
    }
  ];

  @Input('selected-client') set selectClient(client: any) {
    if (client) {
      this.client = client;

      this.paymentService.getCustumerCreditCard(this.client.personId).subscribe(
        data => {
          try {
            const cardinfo = data.objects.Stripe[0];
            this.tempCardInformation =
              '' +
              cardinfo.brand +
              ' Card Found with the last 4 digits of ' +
              cardinfo.last4 +
              '';
            this.paymentSelected.emit({ label: 'Card is added;' });
          } catch (e) {
            console.error(e);
          }
        },
        error => {
          this.onIsError();
        }
      );
    }
  }
  @Input('plan') set selectPlan(plan: any) {
    if (plan) {
      this.plan = plan;
    }
  }
  @Input('client-plan') set selectclientPlan(clientPlan: any) {
    if (clientPlan) {
      this.clientPlan = clientPlan;

      //
    }
  }
  @Output() paymentSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private paymentService: PaymentService,
    private fb: FormBuilder,
    private stripeService: StripeService
  ) {}

  stripeResponseHandler() {
    console.log('Handled');
  }

  ngOnInit() {
    this.stripeTest = this.fb.group({
      // name: ["", [Validators.required]]
    });
    this.stripeService.elements(this.elementsOptions).subscribe(elements => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.card = this.elements.create('card', {
          style: {
            base: {
              iconColor: '#666EE8',
              color: '#e6e6f0',
              lineHeight: '40px',
              fontWeight: 300,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: '18px',
              '::placeholder': {
                color: '#CFD7E0'
              }
            }
          }
        });
        this.card.mount('#card-element');
      }
    });
  }

  ngOnDestroy(): void {}

  buy() {
    this.stripeService
      .createToken(this.card, { name: this.client.title || 'Plan Charge' })
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges

          const token = result.token;
          this.paymentService
            .creatCustumerCreditCard({
              cardToken: token.id,
              clientId: this.client.personId,
              email: this.client.email,
              sourceType: 'card'
            })
            .subscribe(
              data => {
                let msg: string;
                let typeMessage: string;
                if (data.success === 1) {
                  msg = data.objects.msg;
                  typeMessage = 'success';
                  this.paymentSelected.emit({ label: 'Card is added;' });
                } else {
                  msg = 'Ooops, On error has ocurred!';
                  typeMessage = 'danger';
                }
                notify(
                  null,
                  typeMessage,
                  'bounceInRight',
                  'bounceOutRight',
                  null,
                  msg
                );
              },
              error => {
                this.onIsError();
              }
            );
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }
  // createSourceACH() {
  //   this.stripeScriptTag.StripeInstance.createSource({
  //     type: 'ach_credit_transfer',
  //     currency: 'usd',
  //     owner: {
  //       name: this.client.first_name + ' ' + this.client.last_name,
  //       email: this.client.email
  //     }
  //   }).then(result => {
  //     if (!result.error) {
  //       // console.info(result.source);
  //       const token = result.source.id;
  //       this.paymentService
  //         .creatCustumerCreditCard({
  //           cardToken: token,
  //           clientId: this.clientPlan.clientId,
  //           email: this.client.email,
  //           sourceType: result.source.type
  //         })
  //         .subscribe(
  //           data => {
  //             let msg: string;
  //             let typeMessage: string;
  //             if (data.success === 1) {
  //               msg = data.objects.msg;
  //               typeMessage = 'success';
  //             } else {
  //               msg = 'Ooops, On error has ocurred!';
  //               typeMessage = 'danger';
  //             }
  //             notify(
  //               null,
  //               typeMessage,
  //               'bounceInRight',
  //               'bounceOutRight',
  //               null,
  //               msg
  //             );
  //           },
  //           error => {
  //             this.onIsError();
  //           }
  //         );
  //     } else {
  //       // console.info(result.error);
  //       notify(
  //         null,
  //         'danger',
  //         'bounceInRight',
  //         'bounceOutRight',
  //         null,
  //         'Ooops, On error has ocurred!'
  //       );
  //     }
  //   });
  // }
  // setStripeToken(stripeToken: StripeToken) {
  //   console.info('trying to set the token');
  //   if (stripeToken) {
  //     const token = stripeToken.id;
  //     this.paymentService
  //       .creatCustumerCreditCard({
  //         cardToken: token,
  //         clientId: this.clientPlan.clientId,
  //         email: this.client.email,
  //         sourceType: 'card'
  //       })
  //       .subscribe(
  //         data => {
  //           let msg: string;
  //           let typeMessage: string;
  //           if (data.success === 1) {
  //             msg = data.objects.msg;
  //             typeMessage = 'success';
  //           } else {
  //             msg = 'Ooops, On error has ocurred!';
  //             typeMessage = 'danger';
  //           }
  //           notify(
  //             null,
  //             typeMessage,
  //             'bounceInRight',
  //             'bounceOutRight',
  //             null,
  //             msg
  //           );
  //         },
  //         error => {
  //           this.onIsError();
  //         }
  //       );
  //   } else {
  //     notify(
  //       null,
  //       'danger',
  //       'bounceInRight',
  //       'bounceOutRight',
  //       null,
  //       'Ooops, On error has ocurred!'
  //     );
  //   }
  // }

  onIsError(): void {
    setTimeout(() => {
      notify(
        null,
        'danger',
        'bounceInRight',
        'bounceOutRight',
        null,
        'Ooops, On error has ocurred!'
      );
    }, 0);
  }
}
