import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { PlanService } from '../../services/plan/plan.service';

@Component({
  selector: 'plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
  @Input() isAdmin: boolean;

  @Input('preselected-plan') set preselectedPlan(planID: any) {
    if (planID) {
      this.planID = planID;
      for (const key in this.plans) {
        if (this.plans.hasOwnProperty(key)) {
          const element = this.plans[key];
          if (element.planId === this.planID) {
            this.selectedPlan = element;
          }
        }
      }
    }
  }

  @Output() detailClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() selectPlanClick: EventEmitter<any> = new EventEmitter<any>();

  plans: any = [];
  planID;
  selectedPlan: any;

  constructor(private planService: PlanService) {}

  ngOnInit() {
    this.loadPlans();
  }

  loadPlans() {
    this.planService.getPlansByStatus(1).subscribe(data => {
      this.plans = data.objects;
    });
  }

  onClickDetails(): void {
    this.detailClick.emit();
  }

  selectPlan(plan): void {
    this.selectedPlan = plan;
    this.selectPlanClick.emit({ plan });
  }
  isSelected(plan) {
    if (plan && this.selectedPlan) {
      return this.selectedPlan && plan.planId === this.selectedPlan.planId;
    }
    return false;
  }
}
