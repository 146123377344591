import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'details-session',
  templateUrl: './details-session.component.html',
  styleUrls: ['./details-session.component.scss']
})
export class DetailsSessionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
