import { Component, OnInit } from '@angular/core';
import { ReportService } from '../../services/report/report.service';

@Component({
  selector: 'app-datatables',
  templateUrl: './datatables.component.html',
  styleUrls: ['./datatables.component.scss']
})
export class DatatablesComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  headers = [
    { title: 'ID', data: 'ID', dbColumns: ['per.personId'], searchable: true },
    { title: 'NAME', data: 'NAME', dbColumns: ['per.first_name', 'per.last_name'], searchable: true },
    { title: 'PHONE #', data: 'PHONE #', dbColumns: ['per.phone'], searchable: true },
    { title: 'MEMBERSHIP NAME', data: 'MEMBERSHIP NAME', dbColumns: ['p.title'], searchable: true },
    { title: 'DATE CREATED', data: 'DATE CREATED', dbColumns: ['per.phone'], searchable: true },
    { title: 'DATE JOINED', data: 'DATE JOINED', dbColumns: ['per.phone'], searchable: true },
    { title: 'MONTHLY REVENUE', data: 'MONTHLY REVENUE', dbColumns: ['per.phone'], searchable: true },
    { title: 'STATUS', data: 'STATUS', dbColumns: [], searchable: false },
    { title: 'STATE', data: 'STATE', dbColumns: ['per.state'], searchable: true, visible: false },
    { title: 'LEAD TRAINER', data: 'LEAD TRAINER', dbColumns: [], searchable: false, visible: false }
  ];

  constructor(private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.typeReport = '2';
        dataTablesParameters.startDate = '2019-01-01';
        dataTablesParameters.endDate = '2019-05-31';
        dataTablesParameters.columnsReport = this.headers;
        this.reportService.getClientsReport(dataTablesParameters)
          .subscribe(
            data => {
              // this.results = data["data"];
              callback({
                recordsTotal: data.recordsTotal,
                recordsFiltered: data.recordsFiltered,
                data: data["data"]
              });
            }
          );
      },
      columns: this.headers
    };
  }
}
