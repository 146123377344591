import { Component, OnInit } from '@angular/core';

import { ChatService } from '../../services/chat/chat.service';
import { MessageService } from '../../services/message/message.service';
import { AuthService } from '../../services/authorization/auth.service';
import { TrainerService } from '../../services/trainer/trainer.service';
import { LeadTrainerService } from '../../services/leadtrainer/leadtrainer.service';
import { AccountService } from '../../services/registration/registration.service';

import { SERVER_URL } from "../../general/general.variables";

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  serverUrl: string;
  userName: String;
  pictureProfile: String;
  userId;
  selectConversation: any;
  messageText: String;
  arrayMessage: Array<any> = [];
  conversations: Array<any> = [];
  newConversation = false;
  contacts: Array<any> = [];

  constructor(
    private chatService: ChatService,
    public authService: AuthService,
    public messageService: MessageService,
    private accountService: AccountService,
    private trainerService: TrainerService,
    private leadTrainerService: LeadTrainerService) {
  }

  ngOnInit() {
    this.serverUrl = SERVER_URL;
    this.userName = this.authService.getCurrentUser()['first_name'] + this.authService.getCurrentUser()['last_name'];
    this.userId = this.authService.getCurrentUser()['userId'];
    this.pictureProfile = this.authService.getCurrentUser()['pictureProfile'];
    this.initConversation();
    this.chatService.messageReceived()
      .subscribe(
        data => {
          if (Array.isArray(data)) {
            this.arrayMessage = data;
          } else {
            if (this.selectConversation.roomName == data.room) {
              this.arrayMessage.push(data);
            }
          }
        });
    this.chatService.conversationsReceived()
      .subscribe(
        data => {
          if (Array.isArray(data)) {
            this.conversations = data;
          } else {
            this.pushElementInConversations(data);
          }
        }
      );
    this.loadConversations();
    let userType: number = this.authService.getCurrentUser()['userType'];
    let id: number;
    if (userType != 1) {
      id = this.authService.getCurrentUser()['trainerId'];
    } else {
      id = this.authService.getCurrentUser()['clientTrainerId'];
    }
    this.loadContacts(userType, id);
  }

  loadConversations() {
    this.messageService.getConversationsByUser(this.userId)
      .subscribe(
        data => {
          this.conversations = data.objects;
        }
      );
  }

  pushElementInConversations(data: any) {
    let exists = false;
    this.conversations.forEach(element => {
      if (element.clientTrainerId == data.clientTrainerId) {
        if (data.created) {
          element.date = data.created;
          element.message = data.message;
        } else {
          element.date = data.date;
        }
        exists = true;
        return;
      }
    });
    if (!exists) {
      this.conversations.push(data);
    }
    let sortConversations = this.conversations.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });
    this.conversations = sortConversations;
  }

  initConversation() {
    this.selectConversation = {
      clientTrainerId: null,
      conversation: null,
      date: null,
      message: null,
      pictureConversation: null,
      roomJoined: null,
      roomName: null
    }
  }

  fnNewConversation() {
    this.newConversation = true;
  }

  cancelNewConversation() {
    this.newConversation = false;
  }

  fnSelectContact(contact: any) {
    let newConversation = {
      clientTrainerId: contact.clientTrainerId,
      conversation: contact.first_name + ' ' + contact.last_name,
      date: new Date(),
      message: null,
      pictureConversation: contact.pictureProfile,
      roomJoined: null,
      roomName: null
    }
    this.pushElementInConversations(newConversation);
    this.fnSelectConversation(newConversation);
    this.cancelNewConversation();
  }

  fnSelectConversation(conversation: any) {
    this.selectConversation = conversation;
    if (this.selectConversation.roomJoined) {
      console.log(this.selectConversation.roomJoined);
      console.log('Already joined to room. Name room: ' + this.selectConversation.roomName);
      this.loadConversations();
    } else {
      this.selectConversation.roomName = 'room_' + this.selectConversation.clientTrainerId;
      this.joinRoom();
      this.selectConversation.roomJoined = true;
      this.conversations.forEach(element => {
        if (element.clientTrainerId == this.selectConversation.clientTrainerId) {
          element.roomJoined = true;
          return;
        }
      });
    }
  }

  joinRoom() {
    this.chatService.joinRoom({ room: this.selectConversation.roomName, clientTrainerId: this.selectConversation.clientTrainerId, userId: this.userId });
  }

  sendMessage() {
    let message = {
      'userName': this.userName,
      'clientTrainerId': this.selectConversation.clientTrainerId,
      'userId': this.userId,
      'message': this.messageText,
      'room': this.selectConversation.roomName,
      'pictureProfile': this.pictureProfile
    };
    this.chatService.sendMessage(message);
    this.messageText = null;
  }

  loadContacts(typeUser, trainerId) {
    switch (typeUser) {
      case 0:
        this.trainerService.getClientsTrainer(trainerId, 1).subscribe(data => { this.contacts = data.objects; });
        break;
      case 1:
        let contact = {
          clientTrainerId: trainerId,
          first_name: this.authService.getCurrentUser()['firstNameTrainer'],
          last_name: this.authService.getCurrentUser()['lastNameTrainer'],
          pictureProfile: this.authService.getCurrentUser()['pictureProfileTrainer']
        }
        this.contacts.push(contact);
        break;
      case 2:
        this.leadTrainerService.getClientsLeadTrainerStatus(trainerId, 1).subscribe(data => { this.contacts = data.objects; });
        break;
      case 3:
        this.accountService.getPersonsByTypeUser(1).subscribe(data => { this.contacts = data.response.objects; });
        break;
    }
  }

}
