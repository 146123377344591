import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { StripeScriptTag } from 'stripe-angular';

import { AuthService } from '../../../services/authorization/auth.service';
import { PlanService } from '../../../services/plan/plan.service';

import { SERVER_URL } from '../../../general/general.variables';
import { Subject } from 'rxjs';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'client-plan-detail',
  templateUrl: './client-plan-detail.component.html',
  styleUrls: ['./client-plan-detail.component.scss']
})
export class ClientPlanDetailComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  serverUrl: string;
  selectedClient: any = {};
  clientPlan: any;
  plan: any;
  client: any;
  leadTrainerId;
  readonlydate: true;
  @Input('selected-client') set selectClient(client: any) {
    if (client) {
      this.client = client;
      this.reloadClientPlan();
    }
  }
  @Input('plan') set selectPlan(plan: any) {
    if (plan) {
      this.plan = plan;
      this.loadClientPlan();
    }
  }
  @Input('client-plan') set selectclientPlan(clientPlan: any) {
    if (clientPlan) {
      this.clientPlan = clientPlan;
    }
  }

  // @Output() : EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private authService: AuthService,
    private planService: PlanService,
    private datepipe: DatePipe,
    public stripeScriptTag: StripeScriptTag
  ) {
    this.stripeScriptTag.setPublishableKey(this.planService.getStripeToken());
    this.serverUrl = SERVER_URL;
    this.leadTrainerId = this.authService.getCurrentUser().trainerId;
  }

  ngOnInit() {}
  ngAfterViewInit() {}

  ngOnDestroy(): void {}

  loadClientPlan() {
    if (!this.clientPlan) {
      const startDate = new Date();
      const endDate = new Date().setDate(new Date(startDate).getDate() + 30);
      this.clientPlan = {};
      this.clientPlan.clientId = this.client.personId;
      this.clientPlan.planId = this.plan.planId;
      this.clientPlan.leadTrainerId = this.leadTrainerId;
      this.clientPlan.startDate = this.datepipe.transform(startDate, 'yyyy-MM-dd');
      this.clientPlan.expiredDate = this.datepipe.transform(endDate, 'yyyy-MM-dd');
      this.clientPlan.discount = 0;
      this.changeDiscount();
    }
  }
  reloadClientPlan() {
    if (this.client.clientPlanId) {
      this.planService.getClientPlanById(this.client.clientPlanId).subscribe(data => {
        const object = data.objects[0];
        this.clientPlan = {};

        this.clientPlan.frecuencyPaymentId = object.frecuencyPaymentId;
        this.clientPlan.clientPlanId = object.clientPlanId;
        this.clientPlan.clientId = object.clientId;
        this.clientPlan.planId = object.planId;
        this.clientPlan.leadTrainerId = object.leadTrainerId;
        this.clientPlan.startDate = this.datepipe.transform(object.startDate, 'yyyy-MM-dd');
        this.clientPlan.expiredDate = this.datepipe.transform(object.endDate, 'yyyy-MM-dd');
        this.clientPlan.discount = object.discount || 0;
        this.changeDiscount();
      });
    }
  }

  updatePlanClient() {
    this.clientPlan.expiredDate = new Date().setDate(new Date(this.clientPlan.startDate).getDate() + 30);
    this.clientPlan.startDate = this.datepipe.transform(this.clientPlan.startDate, 'yyyy-MM-dd');
    this.clientPlan.expiredDate = this.datepipe.transform(this.clientPlan.expiredDate, 'yyyy-MM-dd');
  }

  addPlanClient() {
    this.clientPlan.clientId = this.client.personId;
    this.clientPlan.planId = this.plan.planId;
    this.clientPlan.leadTrainerId = this.client.leadTrainerId;
    this.planService.assignPlanToClient(this.clientPlan).subscribe(
      data => {
        let msg: string;
        let typeMessage: string;
        if (data.success) {
          msg = data.objects.msg;
          typeMessage = 'success';
          this.clientPlan.clientPlanId = data.objects.clientPlanId;
        } else {
          msg = 'Ooops, On error has ocurred!';
          typeMessage = 'danger';
        }
        notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
      },
      error => {
        this.onIsError();
      }
    );
  }
  onIsError(): void {
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
    }, 4000);
  }

  loadTotal() {
    this.clientPlan.totalBD = this.plan.sessionsPerMonth * this.plan.costPerSession;
  }
  changeDiscount() {
    this.loadTotal();
    this.clientPlan.total = this.clientPlan.totalBD - this.clientPlan.totalBD * (this.clientPlan.discount / 100);
  }
}
