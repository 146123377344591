import { Component, OnInit } from '@angular/core';
import { SERVER_URL } from 'src/app/general/general.variables';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/authorization/auth.service';

export interface IMedia {
  title: string;
  src: string;
  type: string;
  code: string;
}

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss']
})
export class TutorialsComponent implements OnInit {

  playlist: IMedia[] = [
    {
      title: 'Check Out',
      src: SERVER_URL + 'videos/TV_01.MP4',
      type: 'video/mp4',
      code: 'TV_01'
    },
    {
      title: 'Update Payment Info',
      src: SERVER_URL + 'videos/TV_02.MP4',
      type: 'video/mp4',
      code: 'TV_02'
    },
    {
      title: 'Check Balance',
      src: SERVER_URL + 'videos/TV_03.MP4',
      type: 'video/mp4',
      code: 'TV_03'
    },
    {
      title: 'Rescheduling',
      src: SERVER_URL + 'videos/TV_04.MP4',
      type: 'video/mp4',
      code: 'TV_04'
    }
  ];

  currentItem: IMedia;

  constructor(private route: ActivatedRoute, public authService: AuthService) { }

  ngOnInit() {
    let codeTV = this.route.snapshot.queryParamMap.get('cv');
    if (codeTV) {
      this.currentItem = this.playlist.find(element => element.code == codeTV);
    } else {
      this.currentItem = this.playlist[0];
    }
  }

  onClickPlaylistItem(item: IMedia) {
    this.currentItem = item;
  }

}
