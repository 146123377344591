import { Component, OnInit } from '@angular/core';
// import { Router } from '@angular/router';

import { AuthService } from '../../services/authorization/auth.service';
import { SERVER_URL } from "../../general/general.variables";

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public serverUrl: string;

  constructor(
    public authService: AuthService,
    // private router: Router
    ) { }

  ngOnInit() {
    this.serverUrl = SERVER_URL;
  }

  onLogout(): void {
    this.authService.logout();
    // this.router.navigate(['/']);
    // this.router.navigateByUrl('/');
    window.location.href = "/";
  }

}
