import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AccountService } from '../../services/registration/registration.service';
import { MeasurementService } from '../../services/measurement/mesurement.service';

import { SERVER_URL } from "../../general/general.variables";

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'app-detail-measurement',
  templateUrl: './detail-measurement.component.html',
  styleUrls: ['./detail-measurement.component.scss']
})
export class DetailMeasurementComponent implements OnInit {

  public client: any = null;
  public clientId = null;
  public isError = false;
  public serverUrl: string;
  public clientMeasurements: any[];

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    public measurementService: MeasurementService) { }

  ngOnInit() {
    this.clientId = this.route.snapshot.params['clientId'];
    this.serverUrl = SERVER_URL;
    this.getClient();
    this.loadMeasurements();
  }

  getClient() {
    this.accountService.getPerson(this.clientId).subscribe(data => { this.client = data.response.objects[0]; }, error => { this.onIsError(); });
  }

  loadMeasurements() {
    this.measurementService.getGetAllMeasurements()
      .subscribe(
        data => {
          let measurements = data.objects;
          let objects = [];
          measurements.forEach(element => {
            objects.push({ clientId: this.clientId, measurementId: element.measurementId, nameMeasurement: element.name, measurement: null });
          });
          this.clientMeasurements = objects;
        },
        error => {
          this.onIsError();
        });
  }

  saveMeasurements() {
    // console.log(this.clientMeasurements);
    this.measurementService.saveMeasurements(this.clientMeasurements)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects.msg;
            typeMessage = 'success';
            this.clientMeasurements = null;
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError();
        }
      );
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
      this.isError = false;
    }, 4000);
  }
}
