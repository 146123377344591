import { ContactLogsComponent } from './components/contact-logs/contact-logs.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AccountComponent } from './components/account/account.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { UsersComponent } from './components/users/users.component';
import { DetailsUserComponent } from './components/details-user/details-user.component';
import { SessionsComponent } from './components/sessions/sessions.component';
import { DetailsSessionComponent } from './components/details-session/details-session.component';
import { MeasurementsComponent } from './components/measurements/measurements.component';
import { DetailMeasurementComponent } from './components/detail-measurement/detail-measurement.component';
import { MessagesComponent } from './components/messages/messages.component';
import { DetailQuestionaryComponent } from './components/detail-questionary/detail-questionary.component';
import { NotesComponent } from './components/notes/notes.component';
import { ListPlansComponent } from './components/list-plans/list-plans.component';
import { DetailPlanComponent } from './components/detail-plan/detail-plan.component';
import { ClientPlanComponent } from './components/client-plan/client-plan.component';
import { ClientPlanManagerComponent } from './components/client-plan-manager/client-plan-manager.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportStaffComponent } from './components/report-staff/report-staff.component';
import { ReportClientsComponent } from './components/report-clients/report-clients.component';
import { ReportPaymentComponent } from './components/report-payment/report-payment.component';
import { DatatablesComponent } from './pages/datatables/datatables.component';

import { AuthGuard } from './guard/auth.guard';
import { ReportAuditComponent } from './pages/report-audit/report-audit.component';
import { PaystubComponent } from './pages/paystub/paystub.component';
import { SalaryComponent } from './pages/salary/salary.component';
import { DetailSalaryComponent } from './pages/detail-salary/detail-salary.component';
import { DetailPaystubComponent } from './pages/detail-paystub/detail-paystub.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { ReportSalesComponent } from './pages/report-sales/report-sales.component';
import { TutorialsComponent } from './pages/tutorials/tutorials.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:success', component: LoginComponent },
  { path: 'confirmation', component: LoginComponent },
  { path: 'account', component: AccountComponent },
  { path: 'confirmation/:code', component: ConfirmationComponent },
  { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },
  { path: 'users', canActivate: [AuthGuard], component: UsersComponent },
  { path: 'user', canActivate: [AuthGuard], component: DetailsUserComponent },
  { path: 'sessions', canActivate: [AuthGuard], component: SessionsComponent },
  { path: 'detailsession/:id', canActivate: [AuthGuard], component: DetailsSessionComponent },
  { path: 'measurements', canActivate: [AuthGuard], component: MeasurementsComponent },
  { path: 'measurements/:clientId', canActivate: [AuthGuard], component: DetailMeasurementComponent },
  { path: 'messages', canActivate: [AuthGuard], component: MessagesComponent },
  { path: 'questionary', canActivate: [AuthGuard], component: DetailQuestionaryComponent },
  { path: 'notes', canActivate: [AuthGuard], component: NotesComponent },
  { path: 'plans', canActivate: [AuthGuard], component: ListPlansComponent },
  { path: 'detailplan', canActivate: [AuthGuard], component: DetailPlanComponent },
  { path: 'clientplan', canActivate: [AuthGuard], component: ClientPlanManagerComponent },
  { path: 'clientplanmanage', canActivate: [AuthGuard], component: ClientPlanComponent },
  { path: 'reports', canActivate: [AuthGuard], component: ReportsComponent },
  { path: 'reports-staff', canActivate: [AuthGuard], component: ReportStaffComponent },
  { path: 'reports-clients', canActivate: [AuthGuard], component: ReportClientsComponent },
  { path: 'reports-payment', canActivate: [AuthGuard], component: ReportPaymentComponent },
  { path: 'reports-sales', canActivate: [AuthGuard], component: ReportSalesComponent },
  { path: 'datatables', canActivate: [AuthGuard], component: DatatablesComponent },
  { path: 'audit', canActivate: [AuthGuard], component: ReportAuditComponent },
  { path: 'paystub', canActivate: [AuthGuard], component: PaystubComponent },
  { path: 'salary', canActivate: [AuthGuard], component: SalaryComponent },
  { path: 'detail-salary', canActivate: [AuthGuard], component: DetailSalaryComponent },
  { path: 'detail-paystub', canActivate: [AuthGuard], component: DetailPaystubComponent },
  { path: 'unauthorized', canActivate: [AuthGuard], component: UnauthorizedComponent },
  { path: 'tutorials', component: TutorialsComponent },
  {
    path: 'contact-logs',
    canActivate: [AuthGuard],
    component: ContactLogsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
