import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from '../authorization/auth.service';
import { SERVER_URL } from 'src/app/general/general.variables';
import { Observable } from 'rxjs';
import { ResponseInterface } from 'src/app/models/authorization/response-interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  
  private urlBase: string;
  private headers: HttpHeaders;

  constructor(public _http: HttpClient, private auth: AuthService) {
      this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
      this.urlBase = SERVER_URL + 'audit/';
  }

  getAuditActions(params: any): Observable<any> {
      return this._http.post<ResponseInterface>(this.urlBase + 'actions', params, { headers: this.headers }).pipe(map(data => data));
  }
}
