import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

import { AccountService } from '../../services/registration/registration.service';
import { ReportService } from '../../services/report/report.service';

import { states } from '../../general/general.variables';
import { DataTableDirective } from 'angular-datatables';
import { headers } from 'src/app/general/report.headers';
import { RegionService } from 'src/app/services/region/region.service';

@Component({
  selector: 'report-staff',
  templateUrl: './report-staff.component.html',
  styleUrls: ['./report-staff.component.scss']
})
export class ReportStaffComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  public barChartOptions: ChartOptions = {
    responsive: true
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = false;
  public barChartPlugins = [];

  dtOptions: any = {};

  params: any = {};
  isError = false;
  states;
  headers = [];
  results: any[];
  typeReport;
  trainer: any;
  nameReport;

  constructor(
    private route: ActivatedRoute,
    private reportService: ReportService,
    public datepipe: DatePipe,
    private regionService: RegionService
  ) { }

  ngOnInit() {
    this.typeReport = this.route.snapshot.queryParamMap.get('typeReport');
    switch (this.typeReport) {
      case '0':
        this.nameReport = 'Payroll';
        break;
      case '1':
        this.nameReport = 'Phonebook';
        break;
      case '2':
        this.nameReport = 'Clients';
        break;
      case '3':
        this.nameReport = 'Trainer Retention';
        break;
    }
    this.initDate();
    if (this.typeReport != 2) {
      this.headers = headers.filter(element => element.typeReport == this.typeReport);
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        serverSide: true,
        processing: true,
        responsive: true,
        searching: false,
        ajax: (dataTablesParameters: any, callback) => {
          dataTablesParameters.typeReport = this.typeReport;
          dataTablesParameters.startDate = this.params.startDate;
          dataTablesParameters.endDate = this.params.endDate;
          dataTablesParameters.columnsReport = this.headers;
          this.reportService.getStaffReport(dataTablesParameters)
            .subscribe(
              data => {
                callback({
                  recordsTotal: data.recordsTotal,
                  recordsFiltered: data.recordsFiltered,
                  data: data["data"]
                });
              }
            );
        },
        columns: this.headers
      };
    } else {
      this.headers = headers.filter(element => element.typeReport == this.typeReport && element.visible);
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        serverSide: true,
        processing: true,
        responsive: true,
        searching: false,
        ajax: (dataTablesParameters: any, callback) => {
          dataTablesParameters.startDate = this.params.startDate;
          dataTablesParameters.endDate = this.params.endDate;
          dataTablesParameters.columnsReport = this.headers;
          this.reportService.getReportTrainerClients(dataTablesParameters)
            .subscribe(
              data => {
                this.results = data["data"];
                callback({
                  recordsTotal: data.recordsTotal,
                  recordsFiltered: data.recordsFiltered,
                  data: []
                });
              }
            );
        }
      };
    }
    this.loadStates();
    this.loadSelects();
  }

  filterHeader(header) {
    return header.searchable;
  }

  initDate() {
    this.params.startDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.params.endDate = this.datepipe.transform(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd');
  }

  public barChartData(data: number, label: string): ChartDataSets[] {
    return [{ data: [data], label: label, backgroundColor: '#CE2C2C' }];
  }

  private loadSelects() {
    this.regionService.getAllRegions()
      .subscribe(
        data => {
          let regions: any[] = data.objects;
          this.headers.forEach(element => {
            if (element.inputType == 'select') {
              let values: any[] = [];
              if (element.title == 'REGION') {
                regions.forEach(element => {
                  values.push({ id: element.regionId, text: element.state + ' - ' + element.name });
                });
              } else {
                this.states.forEach(element => {
                  values.push({ id: element.abbreviation, text: element.abbreviation + ' - ' + element.name });
                });
              }
              element.values = values;
            }
          });
        },
        error => this.onIsError()
      );
  }

  private loadStates() {
    this.states = states;
  }

  generateReport() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  selectTrainer(trainer: any) {
    this.headers = [];
    this.trainer = trainer;
    this.results = this.trainer['Visits'];
    if (this.results.length > 0) {
      for (var i = 0; i < this.results.length; i++) {
        var columnsIn = this.results[i];
        for (var key in columnsIn) {
          this.headers.push(key);
        }
      }
    }
  }

  goBack() {
    this.trainer = null;
    this.headers = [];
    this.results = [];
    this.generateReport();
  }

  printPage() {
    window.print();
  }

  print() {
    var table = document.getElementById('DataTables_Table_0');
    var parent = document.getElementById('DataTables_Table_0_wrapper');
    var lenght = document.getElementById('DataTables_Table_0_length');
    var info = document.getElementById('DataTables_Table_0_info');
    var paginate = document.getElementById('DataTables_Table_0_paginate');
    parent.removeChild(lenght);
    parent.removeChild(info);
    parent.removeChild(paginate);
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 205;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      var pageHeight = 295;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 5;
      pdf.addImage(contentDataURL, 'PNG', 5, position, imgWidth, imgHeight - 5);

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 5, position + 5, imgWidth, imgHeight - 5);
        heightLeft -= pageHeight;
      }
      pdf.save(this.nameReport + '.pdf'); // Generated PDF
      parent.insertBefore(lenght, table);
      parent.appendChild(info);
      parent.appendChild(paginate);
    });
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => { this.isError = false; }, 4000);
  }

}
