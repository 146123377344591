import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as moment from 'moment';
import * as numeral from 'numeral';

@Pipe({ name: 'dynamic' })
@Injectable({
    providedIn: "root"
})
export class DynamicPipe implements PipeTransform {

    transform(value: any, modifier: string) {
        if (!modifier) return value;
        return eval('this.' + modifier + '(\'' + value + '\')')
    }

    percent(value: any): string {
        return numeral(value).format('0.00%');
    }

    money(value: any): string {
        return numeral(value).format('$ 0,0.00');
    }

    decimal(value: any): string {
        return numeral(value).format('0.0[0000]');
    }

    date(value: any): string {
        return moment(value).format('MM-DD-YYYY');
    }

    dateTimeStamp(value: any): string {
        return moment.unix(value).format('MM-DD-YYYY');
    }

    dateTime(value: any): string {
        return moment(value).format('MM-DD-YYYY HH:mm');
    }
}