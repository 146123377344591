import { Component, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { AuthService } from '../../services/authorization/auth.service';
import { LeadTrainerService } from '../../services/leadtrainer/leadtrainer.service';
import { PlanService } from '../../services/plan/plan.service';
import { FrecuencyService } from '../../services/frecuency/frecuency.service';
import { SessionService } from '../../services/session/session.service';
import { PaymentService } from '../../services/payment/payment.service';
import { AccountService } from '../../services/registration/registration.service';

import { SERVER_URL } from '../../general/general.variables';
import { Subject } from 'rxjs';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'client-plan-manager',
  templateUrl: './client-plan-manager.component.html',
  styleUrls: ['./client-plan-manager.component.scss']
})
export class ClientPlanManagerComponent implements OnInit, OnDestroy {
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<any>;
  dtOptions: DataTables.Settings[] = [];
  dtTrigger: Subject<any> = new Subject();

  // private publishableKey: string = "pk_test_0tuHHRN4FR4cBfwfRf8miSRu00kT7kSLD7";
  private publishableKey = 'pk_test_VLmLz9GVuJl1H8U7unCmDaRp00rlaHNuUV';

  clientPlanId;
  leadTrainerId;
  stepIndex = 1;
  client: any;
  plan: any;
  clients: any = [];
  plans: any = [];
  sessions: any = [];

  clientPlan: any = {};

  serverUrl: string;

  paymentMethods: any[] = [
    { methodId: 1, method: 'card', methodText: 'Credit Card' },
    {
      methodId: 2,
      method: 'ach_credit_transfer',
      methodText: 'ACH Credit Transfer'
    }
  ];

  daysOfWeek: any[] = [
    { day: 0, dayText: 'Sunday' },
    { day: 1, dayText: 'Monday' },
    { day: 2, dayText: 'Tuesday' },
    { day: 3, dayText: 'Wednesday' },
    { day: 4, dayText: 'Thursday' },
    { day: 5, dayText: 'Friday' },
    { day: 6, dayText: 'Saturday' }
  ];

  invalidError: any;

  constructor(private authService: AuthService, private leadTrainerService: LeadTrainerService) {}

  ngOnInit() {
    this.initDatatables();
    this.serverUrl = SERVER_URL;
    this.leadTrainerId = this.authService.getCurrentUser().trainerId;
    this.loadClients();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  initDatatables() {
    // this.dtOptions = {
    //   pagingType: 'full_numbers'
    // };
  }

  loadClients() {
    const userType = this.authService.getCurrentUser().userType;
    switch (userType) {
      case 2:
        this.loadClientsLeadTrainer();
        break;
      case 3:
        this.loadAllClients();
        break;
    }
  }

  loadAllClients() {
    this.leadTrainerService.getClients().subscribe(data => {
      console.info(data)
      this.clients = data.objects;
      this.client = this.clients[0]; // TODO
      this.dtTrigger.next();
    });
  }

  loadClientsLeadTrainer() {
    this.leadTrainerService.getClientsLeadTrainerStatus(this.leadTrainerId, 1).subscribe(data => {
      this.clients = data.objects;
      this.client = this.clients[0]; // TODO
      this.dtTrigger.next();
    });
  }

  updateStatus(user: any) {}
}
