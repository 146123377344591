import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ResponseInterface } from "../../models/authorization/response-interface";
import { SERVER_URL } from "../../general/general.variables";

import { AuthService } from "../authorization/auth.service"

@Injectable({
    providedIn: "root"
})
export class TrainerService {
    private urlBase: string;
    private headers: HttpHeaders;

    constructor(public _http: HttpClient, private auth: AuthService) {
        this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
        this.urlBase = SERVER_URL + 'trainers/';
    }

    updateExpertiseTrainer(trainerExpertiseId: number, status: number): Observable<any> {
        return this._http.put<ResponseInterface>(this.urlBase + `expertises/`, { trainerExpertiseId, status }, { headers: this.headers }).pipe(map(data => data));
    }

    updateCertificationTrainer(trainerCertificationId: number, status: number): Observable<any> {
        return this._http.put<ResponseInterface>(this.urlBase + `certifications/`, { trainerCertificationId, status }, { headers: this.headers }).pipe(map(data => data));
    }

    getAllExpertisesTrainer(tainerId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `expertises/`, { headers: this.headers, params: { "trainerId": tainerId } }).pipe(map(data => data));
    }

    getAllCertificationsTrainer(tainerId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `certifications/`, { headers: this.headers, params: { "trainerId": tainerId } }).pipe(map(data => data));
    }

    getExpertisesStatusTrainer(tainerId, status): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `expertises`, { headers: this.headers, params: { "trainerId": tainerId, "status": status } }).pipe(map(data => data));
    }

    getCertificationsStatusTrainer(tainerId, status): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `certifications`, { headers: this.headers, params: { "trainerId": tainerId, "status": status } }).pipe(map(data => data));
    }

    getClientsTrainer(trainerId, status): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + `clients/`, { headers: this.headers, params: { "trainerId": trainerId, "status": status } }).pipe(map(data => data));
    }

    assignClientTrainer(clientId: number, trainerId: number, status: number): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + `clients/`, { clientId, trainerId, status }, { headers: this.headers }).pipe(map(data => data));
    }

    assignLeadTrainerTrainer(trainerId: number, leadTrainerId: number, status: number): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase + `leadtrainer/`, { trainerId, leadTrainerId, status }, { headers: this.headers }).pipe(map(data => data));
    }

    getAllLeadTrainers() {
        return this._http.get(SERVER_URL + `persons`,
            {
                headers: this.headers,
                params: {
                    typeUser: '2', status: '1'
                }
            }
        ).pipe(map(data => data));
    }

    getAllAdministrators() {
        return this._http.get(SERVER_URL + `persons`,
            {
                headers: this.headers,
                params: {
                    typeUser: '3', status: '1'
                }
            }
        ).pipe(map(data => data));
    }

    getTrainerListFromLeadTrainer(trainerId: number) {
        return this._http.get<ResponseInterface>(SERVER_URL + 'leadtrainers/trainers/',
            {
                headers: this.headers,
                params: {
                    leadTrainerId: trainerId.toString(), status: '1'
                }
            }
        ).pipe(map(data => data));
    }
}
