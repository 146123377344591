import { DynamicPipe } from '../pipes/dynamic.pipe';

const pipe: DynamicPipe = new DynamicPipe();

export const headers = [
    { title: 'PERSON_ID', data: 'PERSON_ID', dbColumns: ['sper.personId'], searchable: false, visible: false, typeReport: '0' },
    { title: 'TRAINER', data: 'TRAINER', dbColumns: ['CONCAT(sper.last_name, ", ", sper.first_name)'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '0' },
    { title: 'NUMBER OF SESSIONS', data: 'NUMBER OF SESSIONS', dbColumns: ['sp.numberOfSessions'], searchable: false, typeReport: '0' },
    { title: 'NUMBER OF CLIENTS', data: 'NUMBER OF CLIENTS', dbColumns: ['COUNT(ctr.clientID)'], searchable: false, typeReport: '0' },
    { title: 'NUMBER OF COMPS', data: 'NUMBER OF COMPS', dbColumns: ['COUNT(scomp.sessionId)'], searchable: false, typeReport: '0' },
    { title: 'PAYMENT', data: 'PAYMENT', dbColumns: ['sp.regularRate'], searchable: false, typeReport: '0', pipe: 'money', render: (dataField) => { return pipe.transform(dataField, 'money'); } }, 
    { title: 'NUMBER OF HOURS WORKED', data: 'NUMBER OF HOURS WORKED', dbColumns: ['sp.regularHours'], searchable: false, typeReport: '0', pipe: 'decimal', render: (dataField) => { return pipe.transform(dataField, 'decimal'); } },
    { title: 'PENALTIES', data: 'PENALTIES', dbColumns: ['COUNT(spen.sessionId)'], searchable: false, typeReport: '0' },
    { title: 'EARNINGS', data: 'EARNINGS', dbColumns: [], searchable: false, typeReport: '0' },
    { title: 'STATE', data: 'STATE', dbColumns: ['sper.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '0' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '0' },

    { title: 'PERSON_ID', data: 'PERSON_ID', dbColumns: ['sper.personId'], searchable: false, visible: false, typeReport: '1' },
    { title: 'TRAINER', data: 'TRAINER', dbColumns: ["CONCAT(ptr.first_name, ' ', ptr.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '1' },
    { title: 'EMAIL', data: 'EMAIL', dbColumns: ['ptr.email'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '1' },
    { title: 'PHONE NUMBER', data: 'PHONE NUMBER', dbColumns: ['ptr.phone'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '1' },
    { title: 'ADDRESS', data: 'ADDRESS', dbColumns: ["CONCAT(CASE WHEN ptr.apt_unit IS NULL THEN '' ELSE CONCAT(ptr.apt_unit, ', ') END, ptr.street, ' ', ptr.city, ',', ' ', ptr.zip_code)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '1' },
    { title: 'START DATE', data: 'START DATE', dbColumns: ['ptr.created'], searchable: false, typeReport: '1', render: (dataField) => { return pipe.transform(dataField, 'date'); }  },
    { title: 'STATUS', data: 'STATUS', dbColumns: [], searchable: false, typeReport: '1' },
    { title: 'STATE', data: 'STATE', dbColumns: ['ptr.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '1' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '1' },

    { title: 'TRAINER', data: 'TRAINER', index: 0, dbColumns: ["CONCAT(ptr.first_name, ' ', ptr.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', visible: true, typeReport: '2' },
    { title: 'ATTENDANCE', data: 'ATTENDANCE', dbColumns: [], searchable: false, visible: true, typeReport: '2' },
    { title: 'TRAINER ID', data: 'TRAINER ID', dbColumns: ["tr.trainerId"], searchable: false, visible: false, typeReport: '2' },
    { title: '', data: null, dbColumns: [], searchable: false, orderable: false, visible: true, typeReport: '2' },
    { title: 'STATE', data: 'STATE', dbColumns: ['ptr.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '2' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '2' },

    { title: 'LEAD TRAINER', data: 'LEAD TRAINER', dbColumns: ["CONCAT(pltr.first_name, ' ', pltr.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '3' },
    { title: 'TOTAL TRAINERS', data: 'TOTAL TRAINERS', dbColumns: ["SUM(CASE WHEN tlt.`status` = 1 THEN 1 ELSE 0 END)"], searchable: false, typeReport: '3' },
    { title: 'LOST TRAINERS', data: 'LOST TRAINERS', dbColumns: ["SUM(CASE WHEN tlt.`status` = 0 THEN 1 ELSE 0 END)"], searchable: false, typeReport: '3' },
    { title: 'PERCENT LOST', data: 'PERCENT LOST', dbColumns: [], searchable: false, typeReport: '3', render: (dataField) => { return pipe.transform(dataField, 'percent'); }  },
    { title: 'RETURNED TO PLAN', data: 'RETURNED TO PLAN', dbColumns: [], searchable: false, typeReport: '3' },
    { title: 'PERCENT NO LOST', data: 'PERCENT NO LOST', dbColumns: [], searchable: false, typeReport: '3', render: (dataField) => { return pipe.transform(dataField, 'percent'); } },

    { title: 'CLIENT', data: 'CLIENT', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '4' },
    { title: 'PHONE #', data: 'PHONE #', dbColumns: ['per.phone'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '4' },
    { title: 'LOCATION', data: 'LOCATION', dbColumns: [], searchable: false, typeReport: '4' },
    { title: 'TERM', data: 'TERM', dbColumns: ['pt.name'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '4' },
    { title: 'CONTRACT ASSOCIATED', data: 'CONTRACT ASSOCIATED', dbColumns: [], searchable: false, typeReport: '4' },
    { title: 'BILLING INFORMATION', data: 'BILLING INFORMATION', dbColumns: ['scr.brand'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '4' },
    { title: 'AMOUNT', data: 'AMOUNT', dbColumns: ['cp.total'], searchable: false, typeReport: '4', render: (dataField) => { return pipe.transform(dataField, 'money');} },
    { title: 'STATUS', data: 'STATUS', dbColumns: [], searchable: false, typeReport: '4' },
    { title: 'ALERT', data: 'ALERT', dbColumns: [], searchable: false, typeReport: '4' },
    { title: 'DATE', data: 'DATE', dbColumns: ['cp.startDate'], searchable: false, typeReport: '4', render: (dataField) => { return pipe.transform(dataField, 'date');} },
    { title: 'STATE', data: 'STATE', dbColumns: ['per.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '4' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '4' },

    { title: 'DATE - TIME', data: 'DATE - TIME', dbColumns: [], searchable: false, typeReport: '5', render: (dataField) => { return pipe.transform(dataField, 'dateTime'); } },
    { title: 'CLIENT', data: 'CLIENT', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '5' },
    { title: 'BILLING NAME/TYPE', data: 'BILLING NAME/TYPE', dbColumns: ['scr.brand'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '5' },
    { title: 'AMOUNT', data: 'AMOUNT', dbColumns: [], searchable: false, typeReport: '5', render: (dataField) => { return pipe.transform(dataField, 'money'); } },
    { title: 'SALE', data: 'SALE', dbColumns: [], searchable: false, typeReport: '5' },
    { title: 'AUTH. CODE', data: 'AUTH CODE', dbColumns: [], searchable: false, typeReport: '5' },
    { title: 'ORDER ID', data: 'ORDER ID', dbColumns: ['cp.planId'], searchable: false, typeReport: '5' },
    { title: 'TYPE', data: 'TYPE', dbColumns: ['ct.description'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '5' },
    { title: 'TRANSACTION #', data: 'TRANSACTION #', dbColumns: [], searchable: false, typeReport: '5' },
    { title: 'BATCH #', data: 'BATCH #', dbColumns: [], searchable: false, typeReport: '5' },
    { title: 'LOCATION', data: 'LOCATION', dbColumns: [], searchable: false, typeReport: '5' },
    { title: 'MERCHANT ACCT. #', data: 'MERCHANT ACCT #', dbColumns: [], searchable: false, typeReport: '5' },
    { title: 'STATUS', data: 'STATUS', dbColumns: [], searchable: false, typeReport: '5' },
    { title: 'STATE', data: 'STATE', dbColumns: ['per.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '5' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '5' },

    { title: 'DATE - TIME', data: 'DATE - TIME', dbColumns: [], searchable: false, typeReport: '6', render: (dataField) => { return pipe.transform(dataField, 'dateTime'); } },
    { title: 'CLIENT', data: 'CLIENT', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '6' },
    { title: 'BILLING NAME/TYPE', data: 'BILLING NAME/TYPE', dbColumns: ['scr.brand'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '6' },
    { title: 'AMOUNT', data: 'AMOUNT', dbColumns: [], searchable: false, typeReport: '6', render: (dataField) => { return pipe.transform(dataField, 'money'); } },
    { title: 'SALE', data: 'SALE', dbColumns: [], searchable: false, typeReport: '6' },
    { title: 'AUTH. CODE', data: 'AUTH CODE', dbColumns: [], searchable: false, typeReport: '6' },
    { title: 'ORDER ID', data: 'ORDER ID', dbColumns: ['cp.planId'], searchable: false, typeReport: '6' },
    { title: 'TYPE', data: 'TYPE', dbColumns: ['ct.description'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '6' },
    { title: 'TRANSACTION #', data: 'TRANSACTION #', dbColumns: [], searchable: false, typeReport: '6' },
    { title: 'BATCH #', data: 'BATCH #', dbColumns: [], searchable: false, typeReport: '6' },
    { title: 'LOCATION', data: 'LOCATION', dbColumns: [], searchable: false, typeReport: '6' },
    { title: 'MERCHANT ACCT. #', data: 'MERCHANT ACCT #', dbColumns: [], searchable: false, typeReport: '6' },
    { title: 'STATUS', data: 'STATUS', dbColumns: [], searchable: false, typeReport: '6' },
    { title: 'STATE', data: 'STATE', dbColumns: ['per.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '6' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '6' },

    { title: 'CLIENT', data: 'CLIENT', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '7' },
    { title: 'EMAIL', data: 'EMAIL', dbColumns: ['per.email'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '7' },
    { title: 'PHONE #', data: 'PHONE #', dbColumns: ['per.phone'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '7' },
    { title: 'ACCOUNT BALANCE', data: 'ACCOUNT BALANCE', dbColumns: [], searchable: false, typeReport: '7' },
    { title: '0-29 DAYS AGO', data: '0-29 DAYS AGO', dbColumns: [], searchable: false, typeReport: '7' },
    { title: '30-59 DAYS AGO', data: '30-59 DAYS AGO', dbColumns: [], searchable: false, typeReport: '7' },
    { title: '60-89 DAYS AGO', data: '60-89 DAYS AGO', dbColumns: [], searchable: false, typeReport: '7' },
    { title: '+90 DAYS AGO', data: '+90 DAYS AGO', dbColumns: [], searchable: false, typeReport: '7' },
    { title: 'STATE', data: 'STATE', dbColumns: ['per.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '7' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '7' },

    { title: 'ID', data: 'ID', dbColumns: ['per.personId'], searchable: false, typeReport: '8' },
    { title: 'NAME', data: 'NAME', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '8' },
    { title: 'PHONE', data: 'PHONE', dbColumns: ['per.phone'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '8' },
    { title: 'MEMBERSHIP NAME', data: 'MEMBERSHIP NAME', dbColumns: ['p.title'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '8' },
    { title: 'DATE CREATED', data: 'DATE CREATED', dbColumns: [], searchable: false, typeReport: '8', render: (dataField) => { return pipe.transform(dataField, 'date'); } },
    { title: 'DATE JOINED', data: 'DATE JOINED', dbColumns: [], searchable: false, typeReport: '8', render: (dataField) => { return pipe.transform(dataField, 'date'); } },
    { title: 'MONTHLY REVENUE', data: 'MONTHLY REVENUE', dbColumns: ['fp.description'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '8' },
    { title: 'STATE', data: 'STATE', dbColumns: ['per.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '8' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '8' },

    { title: 'ID', data: 'ID', dbColumns: ['per.personId'], searchable: false, typeReport: '9' },
    { title: 'NAME', data: 'NAME', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '9' },
    { title: 'PHONE #', data: 'PHONE #', dbColumns: ['per.phone'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '9' },
    { title: 'MEMBERSHIP NAME', data: 'MEMBERSHIP NAME', dbColumns: ['p.title'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '9' },
    { title: 'DATE CREATED', data: 'DATE CREATED', dbColumns: ['per.created'], searchable: false, typeReport: '9', render: (dataField) => { return pipe.transform(dataField, 'date'); } },
    { title: 'DATE JOINED', data: 'DATE JOINED', dbColumns: ['cp.created'], searchable: false, typeReport: '9', render: (dataField) => { return pipe.transform(dataField, 'date'); } },
    { title: 'MONTHLY REVENUE', data: 'MONTHLY REVENUE', dbColumns: ['fp.description'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '9' },
    { title: 'STATUS', data: 'STATUS', dbColumns: [], searchable: false, typeReport: '9' },
    { title: 'STATE', data: 'STATE', dbColumns: ['per.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '9' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', visible: false, typeReport: '9' },

    { title: 'LEAD TRAINER', data: 'LEAD TRAINER', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '10' },
    { title: 'TOTAL CLIENTS', data: 'TOTAL CLIENTS', dbColumns: [], searchable: false, typeReport: '10' },
    { title: 'LOST CLIENTS', data: 'LOST CLIENTS', dbColumns: [], searchable: false, typeReport: '10' },
    { title: 'PERCENT LOST', data: 'PERCENT LOST', dbColumns: [], searchable: false, typeReport: '10', render: (dataField) => { return pipe.transform(dataField, 'percent'); } },
    { title: 'RETURNED TO PLAN', data: 'RETURNED TO PLAN', dbColumns: [], searchable: false, typeReport: '10' },
    { title: 'PERCENT NO LOST', data: 'PERCENT NO LOST', dbColumns: [], searchable: false, typeReport: '10', render: (dataField) => { return pipe.transform(dataField, 'percent'); } },

    { title: 'CLIENT', data: 'CLIENT', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '11' },
    { title: 'EMAIL', data: 'EMAIL', dbColumns: ['per.email'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '11' },
    { title: 'PHONE #', data: 'PHONE #', dbColumns: ['per.phone'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '11' },
    { title: 'ADDRESS', data: 'ADDRESS', dbColumns: [], searchable: false, typeReport: '11' },
    { title: 'CITY', data: 'CITY', dbColumns: ['per.city'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '11' },
    { title: 'STATE', data: 'STATE', dbColumns: ['per.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', typeReport: '11' },
    { title: 'ZIP', data: 'ZIP', dbColumns: ['per.zip_code'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '11' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: false, visible: false, search: { regex: false, value: "" }, inputType: 'select', typeReport: '11' },

    { title: 'ID', data: 'ID', dbColumns: ['tlt.leadTrainerId'], searchable: false, visible: false, typeReport: '12' },
    { title: 'LEAD TRAINER', data: 'LEAD TRAINER', dbColumns: ["CONCAT(pltr.first_name, ' ', pltr.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '12' },
    { title: 'STATE', data: 'STATE', dbColumns: ['pltr.state'], searchable: true, search: { regex: false, value: "" }, inputType: 'select', typeReport: '12' },
    { title: 'NUMBER OF PLANS', data: 'NUMBER OF PLANS', dbColumns: ['COUNT(cp.clientPlanId)'], searchable: false, typeReport: '12' },
    { title: 'TOTAL', data: 'TOTAL', dbColumns: ['SUM(cp.total)'], searchable: false, typeReport: '12', render: (dataField) => { return pipe.transform(dataField, 'money'); } },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: false, visible: false, search: { regex: false, value: "" }, inputType: 'select', typeReport: '12' },

    { title: 'ID', data: 'ID', dbColumns: ['tlt.leadTrainerId'], searchable: false, visible: false, typeReport: '13' },
    { title: 'LEAD TRAINER', data: 'LEAD TRAINER', dbColumns: ["CONCAT(pltr.first_name, ' ', pltr.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '13' },
    { title: 'NUMBER OF PLANS', data: 'NUMBER OF PLANS', dbColumns: ['COUNT(cp.clientPlanId)'], searchable: false, typeReport: '13' },
    { title: 'TOTAL', data: 'TOTAL', dbColumns: ['SUM(cp.total)'], searchable: false, typeReport: '13', render: (dataField) => { return pipe.transform(dataField, 'money'); } },
    { title: 'STATE', data: 'STATE', dbColumns: ['pltr.state'], searchable: false, visible: false, search: { regex: false, value: "" }, inputType: 'select', typeReport: '13' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, visible: false, search: { regex: false, value: "" }, inputType: 'select', typeReport: '13' },

    { title: 'ID', data: 'ID', dbColumns: ['tlt.leadTrainerId'], searchable: false, visible: false, typeReport: '14' },
    { title: 'LEAD TRAINER', data: 'LEAD TRAINER', dbColumns: ["CONCAT(pltr.first_name, ' ', pltr.last_name)"], searchable: true, visible: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '14' },
    { title: 'NUMBER OF PLANS', data: 'NUMBER OF PLANS', dbColumns: ['COUNT(cp.clientPlanId)'], searchable: false, typeReport: '14' },
    { title: 'TOTAL', data: 'TOTAL', dbColumns: ['SUM(cp.total)'], searchable: false, typeReport: '14', render: (dataField) => { return pipe.transform(dataField, 'money'); } },
    { title: 'STATE', data: 'STATE', dbColumns: ['pltr.state'], searchable: true, visible: false, search: { regex: false, value: "" }, inputType: 'select', typeReport: '14' },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: false, visible: false, search: { regex: false, value: "" }, inputType: 'select', typeReport: '14' },

    { title: 'CLIENT', data: 'CLIENT', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text', typeReport: '15' },
    { title: 'REFUNDED AMOUNT', data: 'REFUNDED AMOUNT', dbColumns: ['hp.amount'], searchable: false, search: { regex: false, value: "" }, typeReport: '15', render: (dataField) => { return pipe.transform(dataField, 'money'); } },
    { title: 'REFUNDED DATE', data: 'REFUNDED DATE', dbColumns: ['hp.updated'], searchable: false, typeReport: '15', render: (dataField) => { return pipe.transform(dataField, 'date');} },
    { title: 'STATE', data: 'STATE', dbColumns: ['per.state'], searchable: true, search: { regex: false, value: "" }, typeReport: '15', inputType: 'select', visible: false },
    { title: 'REGION', data: 'REGION', dbColumns: ['reg.regionId'], searchable: true, search: { regex: false, value: "" }, typeReport: '15', inputType: 'select' }
];