import { Component, OnInit,  Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { StripeScriptTag } from 'stripe-angular';
import { StripeToken } from 'stripe-angular';

import { AuthService } from '../../../services/authorization/auth.service';
import { LeadTrainerService } from '../../../services/leadtrainer/leadtrainer.service';
import { PlanService } from '../../../services/plan/plan.service';
import { FrecuencyService } from '../../../services/frecuency/frecuency.service';
import { SessionService } from '../../../services/session/session.service';
import { PaymentService } from '../../../services/payment/payment.service';
import { AccountService } from '../../../services/registration/registration.service';

import { SERVER_URL } from '../../../general/general.variables';
import { Subject } from 'rxjs';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'client-plan-profile',
  templateUrl: './client-plan-profile.component.html'
})
export class ClientPlanProfileComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  serverUrl: string;
  selectedClient: any = {};
  @Input("select-client") set selectClient(client: any) {
    if (client) {
      this.selectedClient = client;
      console.info(client)
    }
  }
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private leadTrainerService: LeadTrainerService,
    private planService: PlanService,
    private datepipe: DatePipe,
    private sessionService: SessionService,
    private frecuencyService: FrecuencyService,
    public stripeScriptTag: StripeScriptTag,
    private paymentService: PaymentService,
    private accountService: AccountService
  ) {
    this.stripeScriptTag.setPublishableKey(this.planService.getStripeToken());
    this.serverUrl = SERVER_URL;
  }

  ngOnInit() {}

  ngOnDestroy(): void {}
}
