import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/registration/registration.service';
import { GoalService } from '../../services/goal/goal.service';
import { TitleService } from '../../services/title/title.service';
import { ExpertiseService } from '../../services/expertise/expertise.service';
import { CertificationService } from '../../services/certification/certification.service';
import { states } from '../../general/general.variables';
import { SERVER_URL } from '../../general/general.variables';

declare var $: any;
declare var Dropzone: any;
declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  public person: any;
  private userType: number;
  nameType: string;
  public isError = false;
  public states;
  genders = [{ id: 0, name: 'Male' }, { id: 1, name: 'Female' }];
  goals = [];
  titles = [];
  certifications = [];
  expertises = [];
  myDropzone;

  invitationCode;

  constructor(
    private expertiseService: ExpertiseService,
    private certificationService: CertificationService,
    private titleService: TitleService,
    private goalService: GoalService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.initComponents();
    this.route.queryParams.subscribe(params => {
      this.userType = params['typeUser'];
      this.invitationCode = params['codeInvitation'];
      if (this.invitationCode) {
        this.loadPreRegistration();
      }
      if (this.userType) {
        this.nameType = this.userType == 1 ? 'CLIENT' : 'TRAINER';
        if (this.userType == 1)
          this.initGoals();
        else {
          this.initTitles();
          this.initCertifications();
          this.initExpertises();
        }
      }
    });
    this.initDatePicker();
    this.initDropZone();
  }

  loadPreRegistration() {
    this.accountService.getPreRegistrationByInvitationCode(this.invitationCode)
      .subscribe(
        data => {
          let object = data.objects;
          if (object) {
            this.person = data.objects;
          } else {
            notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'The invitation code has expired. Please request another one.');
            this.initComponents();
          }
        },
        error => this.onIsError()
      );
  }

  initGoals() {
    this.goalService.getAllGoals().subscribe(data => { this.goals = data.response.objects; }, error => this.onIsError());
  }

  initTitles() {
    this.titleService.getAllTitles().subscribe(data => { this.titles = data.response.objects; }, error => this.onIsError());
  }

  initCertifications() {
    this.certificationService.getAllCertifications().subscribe(data => { this.certifications = data.response.objects; }, error => this.onIsError());
  }

  initExpertises() {
    this.expertiseService.getAllExpertises().subscribe(data => { this.expertises = data.response.objects; }, error => this.onIsError());
  }

  initDropZone() {
    Dropzone.autoDiscover = false;
    this.myDropzone = new Dropzone("div#dropzone-upload",
      {
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        maxFiles: this.userType == 1 ? 1 : 2,
        maxFilesize: 5,
        paramName: 'image',
        url: SERVER_URL + 'persons/upload'
      });
    this.myDropzone.pictures = [];
    this.myDropzone.on("success", (file, response) => {
      this.myDropzone.pictures.push(response.response);
    });
  }

  initDatePicker() {
    $(".date-picker").flatpickr({ enableTime: !1, nextArrow: '<i class="zmdi zmdi-long-arrow-right" />', prevArrow: '<i class="zmdi zmdi-long-arrow-left" />' });
  }

  private initComponents(): void {
    this.initPerson();
    this.loadStates();
  }

  private loadStates() {
    this.states = states;
  }

  private initPerson(): void {
    this.person = {
      personId: null,
      first_name: null,
      last_name: null,
      street: null,
      apt_unit: null,
      city: null,
      state: null,
      zip_code: null,
      gender: null,
      phone: null,
      dob: null,
      email: null,
      userName: null,
      password: null,
      userType: null,
      goals: null,
      titleId: null,
      pictures: null
    }
  }

  onSave(): void {
    this.person.userType = this.userType;
    this.person.pictures = this.myDropzone.pictures;
    // console.log(this.person);
    this.accountService.savePerson(this.person)
      .subscribe(
        data => {
          let obj = data.response;
          if (obj.success) {
            this.initComponents();
            this.router.navigate(['/login', true]);
          } else {
            notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
          }
        }, error => this.onIsError());
  }

  onCancel(): void {
    this.initComponents();
    this.router.navigate(['/login']);
  }

  onSavePreRegistration(): void {
    this.person.userType = this.userType;
    this.person.pictures = this.myDropzone.pictures;
    // console.log(this.person);
    this.accountService.saveRegistration(this.person)
      .subscribe(
        data => {
          if (data.success) {
            // this.initComponents();
            this.router.navigate(['/login', true]);
          } else {
            notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
          }
        }, error => this.onIsError());
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(
      () => {
        notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
        this.isError = false;
      }, 4000);
  }

}
