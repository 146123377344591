import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { SERVER_URL } from "../../general/general.variables";

import { AuthService } from "../authorization/auth.service";

@Injectable({
    providedIn: "root"
})
export class ReportService {
    private urlBase: string;
    private headers: HttpHeaders;

    constructor(public _http: HttpClient, private auth: AuthService) {
        this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
        this.urlBase = SERVER_URL + 'reports/';
    }

    getReportTrainerClients(dataTablesParameters: any): Observable<any> {
        return this._http.post<any>(this.urlBase + 'staff/clients', dataTablesParameters, { headers: this.headers }).pipe(map(data => data));
    }

    getStaffReport(dataTablesParameters: any): Observable<any> {
        return this._http.post<any>(this.urlBase + 'staff', dataTablesParameters, { headers: this.headers }).pipe(map(data => data));
    }

    getPaymentsReport(dataTablesParameters: any): Observable<any> {
        return this._http.post<any>(this.urlBase + 'payment', dataTablesParameters, { headers: this.headers }).pipe(map(data => data));
    }

    getSalesReport(dataTablesParameters: any): Observable<any> {
        return this._http.post<any>(this.urlBase + 'sales', dataTablesParameters, { headers: this.headers }).pipe(map(data => data));
    }

    getClientsReport(dataTablesParameters: any): Observable<any> {
        return this._http.post<any>(this.urlBase + 'clients', dataTablesParameters, { headers: this.headers }).pipe(map(data => data));
    }

    getReportHeaders(typeReport): Observable<any> {
        return this._http.get<any>(this.urlBase + 'headers', { headers: this.headers, params: { "typeReport": typeReport } }).pipe(map(data => data));
    }
}