import { Component } from '@angular/core';

import { AuthService } from '../app/services/authorization/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Svetness';
  constructor(
    public authService: AuthService) { }

}
