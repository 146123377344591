import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseInterface } from '../../models/authorization/response-interface';
import { SERVER_URL } from '../../general/general.variables';

import { AuthService } from '../authorization/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  private urlBase: string;
  private headers: HttpHeaders;

  constructor(public _http: HttpClient, private auth: AuthService) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth.getTokenString()}`
    });
    this.urlBase = SERVER_URL + 'plans/';
  }

  assignPlanToClient(planClient: Object): Observable<any> {
    return this._http
      .post<ResponseInterface>(this.urlBase + 'client', planClient, {
        headers: this.headers
      })
      .pipe(map(data => data));
  }

  chargeToClient(planClient: Object): Observable<any> {
    return this._http
      .post<ResponseInterface>(this.urlBase + 'chargeClient', planClient, {
        headers: this.headers
      })
      .pipe(map(data => data));
  }

  savePlan(plan: Object): Observable<any> {
    return this._http
      .post<ResponseInterface>(this.urlBase + 'save', plan, {
        headers: this.headers
      })
      .pipe(map(data => data));
  }
  getStripeToken() {
    return 'pk_test_VLmLz9GVuJl1H8U7unCmDaRp00rlaHNuUV';
  }
  getPlanById(planId): Observable<any> {
    return this._http
      .get<ResponseInterface>(this.urlBase, {
        headers: this.headers,
        params: { planId }
      })
      .pipe(map(data => data));
  }

  getAllPlans(): Observable<any> {
    return this._http
      .get<ResponseInterface>(this.urlBase, { headers: this.headers })
      .pipe(map(data => data));
  }

  getPlansByStatus(status): Observable<any> {
    return this._http
      .get<ResponseInterface>(this.urlBase, {
        headers: this.headers,
        params: { status }
      })
      .pipe(map(data => data));
  }

  getClientPlanById(clientPlanId): Observable<any> {
    return this._http
      .get<ResponseInterface>(this.urlBase + 'client', {
        headers: this.headers,
        params: { clientPlanId }
      })
      .pipe(map(data => data));
  }

  getClientPlansByTrainer(trainerId): Observable<any> {
    return this._http
      .get<ResponseInterface>(this.urlBase + 'client', {
        headers: this.headers,
        params: { trainerId }
      })
      .pipe(map(data => data));
  }

  getPlansByClient(clientId): Observable<any> {
    return this._http
      .get<ResponseInterface>(this.urlBase + 'client', {
        headers: this.headers,
        params: { clientId }
      })
      .pipe(map(data => data));
  }

  getClientPlansByLeadTrainer(leadTrainerId): Observable<any> {
    return this._http
      .get<ResponseInterface>(this.urlBase + 'client', {
        headers: this.headers,
        params: { leadTrainerId }
      })
      .pipe(map(data => data));
  }
}
