import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ResponseInterface } from "../../models/authorization/response-interface";
import { SERVER_URL } from "../../general/general.variables";

import { AuthService } from "../authorization/auth.service"

@Injectable({
    providedIn: "root"
})
export class NoteService {
    private urlBase: string;
    private headers: HttpHeaders;

    constructor(public _http: HttpClient, private auth: AuthService) {
        this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
        this.urlBase = SERVER_URL + 'notes/';
    }

    saveNotes(note: Object): Observable<any> {
        return this._http.post<ResponseInterface>(this.urlBase, note, { headers: this.headers }).pipe(map(data => data));
    }

    updateNote(noteId: number, note: string): Observable<any> {
        return this._http.put<ResponseInterface>(this.urlBase, { noteId, note }, { headers: this.headers }).pipe(map(data => data));
    }

    updateStatusNote(noteId: number, status: number, response: string): Observable<any> {
        return this._http.put<ResponseInterface>(this.urlBase, { noteId, status, response }, { headers: this.headers }).pipe(map(data => data));
    }

    getNotesByUserEmit(userId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'user', { headers: this.headers, params: { "userEmitId": userId } }).pipe(map(data => data));
    }

    getNotesByUserReceives(userId): Observable<any> {
        return this._http.get<ResponseInterface>(this.urlBase + 'user', { headers: this.headers, params: { "userReceivesId": userId } }).pipe(map(data => data));
    }

    getNoteById(noteId): any {
        return this._http.get<ResponseInterface>(this.urlBase + 'user', {
            headers: this.headers,
            params: {
                noteId
            }
        }).pipe(map(data => data));
    }
}