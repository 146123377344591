import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { QuestionaryService } from '../../services/questionary/questionary.service';
import { AuthService } from '../../services/authorization/auth.service';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'detail-questionary',
  templateUrl: './detail-questionary.component.html',
  styleUrls: ['./detail-questionary.component.scss']
})
export class DetailQuestionaryComponent implements OnInit {

  answers: any[];
  clientQuestionaryId = null;

  constructor(
    public route: ActivatedRoute,
    public questionaryService: QuestionaryService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.clientQuestionaryId = params['clientQuestionarieId'];
      this.loadAnswers();
    });
  }

  loadAnswers() {
    this.questionaryService.getAnswersByClientQuestionary(this.clientQuestionaryId)
      .subscribe(
        data => {
          let objects: any[] = data.objects;
          objects.forEach(element => {
            element.edit = false;
            element.editAnswer = null;
          });
          this.answers = objects;
        }
      );
  }

  onClickEdit(index) {
    this.answers[index].edit = true;
    this.answers[index].editAnswer = this.answers[index].value;
  }

  onClickCancel(index) {
    this.answers[index].edit = false;
    this.answers[index].editAnswer = null;
  }

  onClickSave(index) {
    this.questionaryService.updateAnswer(this.answers[index].questionaryId, this.answers[index].editAnswer)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects.msg;
            typeMessage = 'success';
            this.answers[index].edit = false;
            this.answers[index].value = this.answers[index].editAnswer;
            this.answers[index].editAnswer = null;
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError()
        }
      );
  }

  onIsError(): void {
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
    }, 4000);
  }

}
