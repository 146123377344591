import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { AuthService } from '../../../services/authorization/auth.service';
import { LeadTrainerService } from '../../../services/leadtrainer/leadtrainer.service';
import { AccountService } from '../../../services/registration/registration.service';
import { TrainerService } from '../../../services/trainer/trainer.service';

import { SERVER_URL } from '../../../general/general.variables';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'client-plan-trainer',
  templateUrl: './client-plan-trainer.component.html',
  styleUrls: ['./client-plan-trainer.component.scss']
})
export class ClientPlanTrainerComponent implements OnInit, OnDestroy {
  private currentUser: any;
  public isError = false;
  private trainers: [];
  private person: any;
  private selectedTrainer: {};
  private serverUrl;
  private client;

  @Input('selected-client') set selectClient(client: any) {
    if (client) {
      console.info(client);
      this.client = client;
    }
  }

  @Output() onTrainerSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private authService: AuthService,
    private leadTrainerService: LeadTrainerService,
    private trainerService: TrainerService,
    private accountService: AccountService
  ) {
    this.serverUrl = SERVER_URL;
    this.currentUser = this.authService.getCurrentUser();
    this.getDetails(this.currentUser.personId);
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  selectTrainer(trainer) {
    this.selectedTrainer = trainer;
  }

  getDetails(personId) {
    this.accountService.getPerson(personId).subscribe(
      data => {
        this.person = data.response.objects[0];
        switch (this.person.userType) {
          case 0:
          case 1:
          case 2: {
            // Lead trainer
            this.getTrainersByLeadTrainer(this.person.leadTrainerId);
            break;
          }
          case 3: {
            // Admin
            this.getTrainers();
            break;
          }
        }
      },
      error => this.onIsError()
    );
  }

  getTrainersByLeadTrainer(leadTrainerId) {
    this.leadTrainerService.getTrainersLeadTrainerStatus(leadTrainerId, 1).subscribe(
      data => {
        this.trainers = data.objects;
        this.preselectTrainer();
      },
      error => this.onIsError()
    );
  }

  getLeadTrainers() {
    this.accountService.getPersonsByTypeUser(2).subscribe(
      data => {
        this.trainers = data.response.objects;
      },
      error => this.onIsError()
    );
  }

  getTrainers() {
    this.accountService.getPersonsByTypeUser(0).subscribe(
      data => {
        this.trainers = data.response.objects;
        this.preselectTrainer();
      },
      error => this.onIsError()
    );
  }
  preselectTrainer() {
    if (this.client.assignedTrainerId) {
      for (const key in this.trainers) {
        if (this.trainers.hasOwnProperty(key)) {
          const t = this.trainers[key] as any;
          if (t.trainerId == this.client.assignedTrainerId) {
            this.selectedTrainer = t;
          }
        }
      }
    }
  }
  ApproveTrainer(trainer) {
    this.trainerService.assignClientTrainer(this.client.personId, trainer.trainerId, 1).subscribe(
      data => {
        let object = data.response;
        let msg: string;
        if (object.success) {
          console.info("++++++++++++++++++++++++")
          this.onTrainerSelected.emit({ trainer });
        } else {
          msg = 'Ooops, On error has ocurred!';
        }
      },
      error => this.onIsError()
    );
  }
  IsDisabled(selected) {
    return (selected.trainerId == this.client.assignedTrainerId)
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Trainer could not be assigned to client!');
      this.isError = false;
    }, 4000);
  }
}
