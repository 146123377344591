import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { StripeScriptTag } from 'stripe-angular';

import { PlanService } from '../../../services/plan/plan.service';

import { Subject } from 'rxjs';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'client-plan-select',
  templateUrl: './client-plan-select.component.html'
})
export class ClientPlanSelectComponent implements OnInit, OnDestroy {
  plan: any;
  client: any;
  clientPlan: any;
  planId;
  @Input('selected-client') set selectClient(client: any) {
    if (client) {
      this.client = client;
      if (this.client.clientPlanId) {
        this.planService.getClientPlanById(this.client.clientPlanId).subscribe(data => {
          const object = data.objects[0];
          this.planId = object.planId;
        });
      }
    }
  }

  @Output() selectPlanClick: EventEmitter<any> = new EventEmitter<any>();
  constructor(private planService: PlanService, private datepipe: DatePipe, public stripeScriptTag: StripeScriptTag) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  selectPlan(clickObj: any) {
    this.plan = clickObj.plan;
    this.selectPlanClick.emit({ plan: this.plan });
  }
}
