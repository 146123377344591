import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { QuestionaryService } from '../../services/questionary/questionary.service';

@Component({
  selector: 'client-questionaries',
  templateUrl: './questionaries.component.html',
  styleUrls: ['./questionaries.component.scss']
})
export class ClientQuestionariesComponent implements OnInit {

  @Input() clientId: number;

  @Output() detailClick: EventEmitter<any> = new EventEmitter<any>();

  questionaries: any = [];

  constructor(
    private questionaryService: QuestionaryService) { }

  ngOnInit() {
    this.getClientQuestionaries();
  }

  getClientQuestionaries() {
    this.questionaryService.getQuestionariesByClient(this.clientId).subscribe(data => {this.questionaries = data.objects;});
  }

  onClickDetails(): void {
    this.detailClick.emit();
  }

}
