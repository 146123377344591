import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from '../authorization/auth.service';
import { SERVER_URL } from 'src/app/general/general.variables';
import { Observable } from 'rxjs';
import { ResponseInterface } from 'src/app/models/authorization/response-interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SalaryService {
  private urlBase: string;
  private headers: HttpHeaders;

  constructor(public _http: HttpClient, private auth: AuthService) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
    this.urlBase = SERVER_URL + 'salary/';
  }

  getAllSalaries(): Observable<any> {
    return this._http.get<ResponseInterface>(this.urlBase, { headers: this.headers }).pipe(map(data => data));
  }

  getSalaryById(salaryId): Observable<any> {
    return this._http.get<ResponseInterface>(this.urlBase + 'getbyid', { headers: this.headers, params: { salaryId: salaryId } }).pipe(map(data => data));
  }

  getTemplateSalary(): Observable<any> {
    return this._http.get<ResponseInterface>(this.urlBase + 'template', { headers: this.headers }).pipe(map(data => data));
  }

  updateSalary(salary): Observable<any> {
    return this._http.post<ResponseInterface>(this.urlBase + 'update', salary, { headers: this.headers }).pipe(map(data => data));
  }

  saveSalary(salary): Observable<any> {
    return this._http.post<ResponseInterface>(this.urlBase + 'add', salary, { headers: this.headers }).pipe(map(data => data));
  }
}
