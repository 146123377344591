import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { AuditService } from 'src/app/services/audit/audit.service';
import { DatePipe } from '@angular/common';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'report-audit',
  templateUrl: './report-audit.component.html',
  styleUrls: ['./report-audit.component.scss']
})
export class ReportAuditComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: any = {};
  params: any = {};
  isError = false;
  headers = [
    { title: 'AUDITID', data: 'AUDITID', dbColumns: ['aa.actionId'], searchable: false },
    { title: 'NAME', data: 'NAME', dbColumns: ["CONCAT(per.first_name, ' ', per.last_name)"], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text' },
    { title: 'USERNAME', data: 'USERNAME', dbColumns: ['us.userName'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text' },
    { title: 'TYPEUSER', data: 'TYPEUSER', dbColumns: ['tu.typeUser'], searchable: true, search: { regex: false, value: "" }, inputType: 'select' },
    { title: 'DATE-TIME', data: 'DATE-TIME', dbColumns: ['aa.created'], searchable: false, render: (dataField) => { return this.datepipe.transform(dataField, 'MM-dd-yyyy HH:mm'); } },
    { title: 'URL', data: 'URL', dbColumns: ['aa.url'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text' },
    { title: 'IP', data: 'IP', dbColumns: ['aa.ip'], searchable: true, search: { regex: false, value: "" }, inputType: 'input-text' },
    { title: 'METHOD', data: 'METHOD', dbColumns: ['aa.method'], searchable: true, search: { regex: false, value: "" }, inputType: 'select' }
  ];

  selectValues = [
    { id: 1, text: 'POST' },
    { id: 2, text: 'PUT' },
    { id: 3, text: 'GET' },
    { id: 4, text: 'DELETE' }
  ];

  constructor(private auditService: AuditService,
    public datepipe: DatePipe) { }

  ngOnInit() {
    this.initDate();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      responsive: true,
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.startDate = this.params.startDate;
        dataTablesParameters.endDate = this.params.endDate;
        dataTablesParameters.columnsReport = this.headers;
        this.auditService.getAuditActions(dataTablesParameters)
          .subscribe(
            data => {
              callback({
                recordsTotal: data.recordsTotal,
                recordsFiltered: data.recordsFiltered,
                data: data["data"]
              });
            }
          );
      },
      columns: this.headers
    };
  }

  filterHeader(header) {
    return header.searchable;
  }

  initDate() {
    this.params.startDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.params.endDate = this.datepipe.transform(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 'yyyy-MM-dd');
  }

  generateReport() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }

  printPage() {
    window.print();
  }

  print() {
    var table = document.getElementById('DataTables_Table_0');
    var parent = document.getElementById('DataTables_Table_0_wrapper');
    var lenght = document.getElementById('DataTables_Table_0_length');
    var info = document.getElementById('DataTables_Table_0_info');
    var paginate = document.getElementById('DataTables_Table_0_paginate');
    parent.removeChild(lenght);
    parent.removeChild(info);
    parent.removeChild(paginate);
    var data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 205;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      var pageHeight = 295;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 5;
      pdf.addImage(contentDataURL, 'PNG', 5, position, imgWidth, imgHeight - 5);

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 5, position + 5, imgWidth, imgHeight - 5);
        heightLeft -= pageHeight;
      }
      pdf.save('AuditActions.pdf'); // Generated PDF
      parent.insertBefore(lenght, table);
      parent.appendChild(info);
      parent.appendChild(paginate);
    });
  }

}
