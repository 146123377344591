import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../services/authorization/auth.service';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  code: string;
  message: string;
  status: string;
  heading: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.code = this.route.snapshot.params['code'];
    if (this.code) {
      this.confirmationEmail();
    }
  }

  confirmationEmail() {
    this.authService.getMessageConfirmationCode(this.code)
      .subscribe(
        data => {
          let object = data.response;
          this.status = object.objects[0].code;
          switch (object.objects[0].code) {
            case 0:
              this.heading = 'On Error has ocurred';
            case 1:
            case 2:
              this.heading = 'Well done!';
              break;
          }
          this.message = object.objects[0].msg;
        }
      );
  }
}
