import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { ArchwizardModule } from 'angular-archwizard';
import { Module as StripeModule } from "stripe-angular";
import { NgxStripeModule } from 'ngx-stripe';
import { ChartsModule } from 'ng2-charts';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProfileComponent } from './components/profile/profile.component';

import { AuthGuard } from './guard/auth.guard';
import { AccountComponent } from './components/account/account.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { UsersComponent } from './components/users/users.component';
import { DetailsUserComponent } from './components/details-user/details-user.component';
import { SessionsComponent } from './components/sessions/sessions.component';
import { DetailsSessionComponent } from './components/details-session/details-session.component';
import { FullCalendarModule } from 'ng-fullcalendar';
import { MeasurementsComponent } from './components/measurements/measurements.component';
import { DetailMeasurementComponent } from './components/detail-measurement/detail-measurement.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ClientQuestionariesComponent } from './components/questionaries/questionaries.component';
import { DetailQuestionaryComponent } from './components/detail-questionary/detail-questionary.component';
import { NotesComponent } from './components/notes/notes.component';
import { ClientplansComponent } from './components/clientplans/clientplans.component';
import { PlansComponent } from './components/plans/plans.component';
import { DetailPlanComponent } from './components/detail-plan/detail-plan.component';
import { ListPlansComponent } from './components/list-plans/list-plans.component';
import { ClientPlanComponent } from './components/client-plan/client-plan.component';
import { ClientPlanManagerComponent } from './components/client-plan-manager/client-plan-manager.component';
import { ReportStaffComponent } from './components/report-staff/report-staff.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportClientsComponent } from './components/report-clients/report-clients.component';
import { ReportPaymentComponent } from './components/report-payment/report-payment.component';
import { DatatablesComponent } from './pages/datatables/datatables.component';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { ReportAuditComponent } from './pages/report-audit/report-audit.component';
import { CallbackPipe } from './pipes/callback.pipe';
import { PaystubComponent } from './pages/paystub/paystub.component';
import { SalaryComponent } from './pages/salary/salary.component';
import { DetailSalaryComponent } from './pages/detail-salary/detail-salary.component';
import { DetailPaystubComponent } from './pages/detail-paystub/detail-paystub.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component'
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { HeaderComponent } from './components/header/header.component';
import { ReportSalesComponent } from './pages/report-sales/report-sales.component';
import { TutorialsComponent } from './pages/tutorials/tutorials.component';
import { ContactLogsComponent } from './components/contact-logs/contact-logs.component';

import { ClientPlanDetailComponent } from './components/client-plan/client-plan-detail/client-plan-detail.component';
import { ClientPlanFrequencyComponent } from './components/client-plan/client-plan-frequency/client-plan-frequency.component';
import { ClientPlanPaymentComponent } from './components/client-plan/client-plan-payment/client-plan-payment.component';
import { WindowRef } from './components/client-plan/client-plan-payment/winref.module';
import { ClientPlanProfileComponent } from './components/client-plan/client-plan-profile/client-plan-profile.component';
import { ClientPlanSelectComponent } from './components/client-plan/client-plan-select/client-plan-select.component';
import { ClientPlanTrainerComponent } from './components/client-plan/client-plan-trainer/client-plan-trainer.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    SidebarComponent,
    ProfileComponent,
    AccountComponent,
    ConfirmationComponent,
    UsersComponent,
    DetailsUserComponent,
    SessionsComponent,
    DetailsSessionComponent,
    MeasurementsComponent,
    DetailMeasurementComponent,
    MessagesComponent,
    ClientQuestionariesComponent,
    DetailQuestionaryComponent,
    NotesComponent,
    ClientplansComponent,
    PlansComponent,
    DetailPlanComponent,
    ListPlansComponent,
    ClientPlanComponent,
    ClientPlanManagerComponent,
    ReportStaffComponent,
    ReportsComponent,
    ReportClientsComponent,
    ReportPaymentComponent,
    DatatablesComponent,
    ReportAuditComponent,
    DynamicPipe,
    CallbackPipe,
    PaystubComponent,
    SalaryComponent,
    DetailSalaryComponent,
    DetailPaystubComponent,
    UnauthorizedComponent,
    HeaderComponent,
    ReportSalesComponent,
    TutorialsComponent,
    ContactLogsComponent,
    ClientPlanDetailComponent,
    ClientPlanFrequencyComponent,
    ClientPlanPaymentComponent,
    ClientPlanProfileComponent,
    ClientPlanSelectComponent,
    ClientPlanTrainerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    FullCalendarModule,
    ArchwizardModule,
    StripeModule.forRoot(),
    NgxStripeModule.forRoot('pk_test_VLmLz9GVuJl1H8U7unCmDaRp00rlaHNuUV'),
    ChartsModule,
    NgxPlaidLinkModule,
    VgCoreModule,
    VgControlsModule
  ],
  exports: [
  ],
  providers: [
    DatePipe,
    WindowRef,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
