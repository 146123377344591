import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AccountService } from '../../services/registration/registration.service';
import { TrainerService } from '../../services/trainer/trainer.service';
import { UserService } from '../../services/user/user.service';
import { PictureService } from '../../services/user/picture.service';

import { SERVER_URL, states } from "../../general/general.variables";
import { ExpertiseService } from 'src/app/services/expertise/expertise.service';
import { CertificationService } from 'src/app/services/certification/certification.service';
import { TitleService } from 'src/app/services/title/title.service';
import { SalaryService } from 'src/app/services/salary/salary.service';
import { LeadTrainerService } from 'src/app/services/leadtrainer/leadtrainer.service';
import { PaymentService } from 'src/app/services/payment/payment.service';

import * as moment from 'moment';
import { DynamicPipe } from 'src/app/pipes/dynamic.pipe';
import { RegionService } from 'src/app/services/region/region.service';
import { Subject } from 'rxjs';

declare function notify(icon, type, animIn, animOut, title, message): any;
declare var $: any;
declare var Dropzone: any;

@Component({
  selector: 'details-user',
  templateUrl: './details-user.component.html',
  styleUrls: ['./details-user.component.scss']
})
export class DetailsUserComponent implements OnInit, OnDestroy {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  public personId;
  public trainerId;
  public person: any = {};
  public isError = false;
  public expertises: [];
  public certifications: [];
  public goals: [];
  public trainers: [];
  public pictures: [];
  public serverUrl;
  genders = [{ id: 0, name: 'Male' }, { id: 1, name: 'Female' }];
  states;
  userType;
  stepIndex = 0;
  myDropzone;
  titles = [];
  certificationsList = [];
  expertisesList = [];
  salary: any;
  payments: any[];
  regions: any[];

  region: any;

  changeRegion = false;

  private pipe: DynamicPipe = new DynamicPipe();

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private pictureService: PictureService,
    private trainerService: TrainerService,
    private userService: UserService,
    private expertiseService: ExpertiseService,
    private certificationService: CertificationService,
    private titleService: TitleService,
    private leadTrainerService: LeadTrainerService,
    private router: Router,
    private salaryService: SalaryService,
    private paymentService: PaymentService,
    private regionService: RegionService
  ) { }

  ngOnInit() {
    this.personId = this.route.snapshot.queryParamMap.get('personId');
    if (!this.personId) {
      $('#modal-user-type').modal('show');
    } else {
      this.getDetails(this.personId);
      setTimeout(() => {
        this.getPictures();
        this.loadPayments();
      }, 200);
    }
    this.serverUrl = SERVER_URL;
    this.loadRegions();
    this.loadStates();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  private initDropZone() {
    Dropzone.autoDiscover = false;
    this.myDropzone = new Dropzone("div#dropzone-upload",
      {
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        maxFiles: this.userType == 1 ? 1 : 2,
        maxFilesize: 5,
        paramName: 'image',
        url: SERVER_URL + 'persons/upload'
      });
    this.myDropzone.pictures = [];
    this.myDropzone.on("success", (file, response) => {
      this.myDropzone.pictures.push(response.response);
    });
  }

  onSelectUserType() {
    $('#modal-user-type').modal('hide');
    this.initDropZone();
    this.initTitles();
    this.initExpertises();
    this.initCertifications();
  }

  onChangeTrainerToLead() {
    $('#modal-user-type').modal('hide');
    $('#modal-trainers').modal('show');
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    this.getTrainers();
  }

  onSelectTrainer(trainer) {
    this.leadTrainerService.changeTrainerToLeadTrainer({ trainerId: trainer.trainerId }).
      subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = 'Change trainer to lead trainer successful!!';
            typeMessage = 'success';
            this.router.navigate(['/users']);
            $('#modal-trainers').modal('hide');
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError();
        }
      );
  }

  private loadPayments() {
    this.payments = [];
    this.paymentService.chargesByClient(this.personId).subscribe(
      data => {
        this.payments = data.objects;
        this.payments.map(element => {
          element.created = this.pipe.dateTimeStamp(element.created);
          element.amount = this.pipe.money(element.amount / 100);
        });
      }
    );
  }

  onCreateRefund(charge) {
    this.paymentService.creatRefundToCostumer({ chargeId: charge.id })
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = 'Create refund successful.';
            typeMessage = 'success';
            this.loadPayments();
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError();
        });
  }

  onCreateUser() {
    this.person.pictures = this.myDropzone.pictures;
    // console.log(this.person);
    this.accountService.savePerson(this.person)
      .subscribe(
        data => {
          let obj = data.response;
          if (obj.success) {
            this.router.navigate(['/users']);
          } else {
            notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
          }
        }, error => this.onIsError());
  }

  goBack() {
    this.router.navigate(['/users']);
    $('#modal-user-type').modal('hide');
  }

  goBack2() {
    this.router.navigate(['/users']);
    $('#modal-trainers').modal('hide');
  }

  private loadStates() {
    this.states = states;
  }

  private loadRegions() {
    this.regionService.getAllRegions().subscribe(data => { this.regions = data.objects; }, error => this.onIsError());
  }

  private initTitles() {
    this.titleService.getAllTitles().subscribe(data => { this.titles = data.response.objects; }, error => this.onIsError());
  }

  private initCertifications() {
    this.certificationService.getAllCertifications().subscribe(data => { this.certificationsList = data.response.objects; }, error => this.onIsError());
  }

  private initExpertises() {
    this.expertiseService.getAllExpertises().subscribe(data => { this.expertisesList = data.response.objects; }, error => this.onIsError());
  }

  getDetails(personId) {

    console.info(personId)
    this.accountService.getPerson(personId)
      .subscribe(
        data => {
          this.person = data.response.objects[0];
          switch (this.person.userType) {
            case 0: {//Trainer
              this.trainerId = this.person.assignedLeadTrainerId;
              this.getExpertises(this.person.trainerId);
              this.getCertifications(this.person.trainerId);
              this.getLeadTrainers();
              this.getSalary(this.person.salaryId);
              break;
            }
            case 1: {//Client
              this.trainerId = this.person.assignedTrainerId;
              this.getGoals(personId);
              this.getTrainersByLeadTrainer(this.person.leadTrainerId);
              break;
            }
            case 2: {//Lead trainer
              this.getExpertises(this.person.trainerId);
              this.getCertifications(this.person.trainerId);
              this.getSalary(this.person.salaryId);
              break;
            }
            case 3: {//Admin
              this.getExpertises(this.person.trainerId);
              this.getCertifications(this.person.trainerId);
              this.getSalary(this.person.salaryId);
              break;
            }
          }
        }, error => this.onIsError());
  }

  getSalary(salaryId) {
    if (salaryId) {
      this.salaryService.getSalaryById(salaryId)
        .subscribe(
          data => {
            this.salary = data.objects[0];
          }
        );
    } else {
      this.salaryService.getTemplateSalary()
        .subscribe(
          data => {
            let template = data.objects;
            this.salary = {};
            this.salary.salaryType = template.salaryType;
            this.salary.frequency = template.frequency;
            this.salary.method = template.method;

          }
        );
    }
  }

  onChangeSalaryType() {
    this.salary.perSessionFixedPrice = 0;
    this.salary.perSessionPercent = 0;
  }

  onCreateRegion(changeRegion) {
    this.changeRegion = changeRegion;
    this.region = {};
    $('#modal-region').modal('show');
  }

  onSaveRegion() {
    this.regionService.createRegion(this.region).
      subscribe(
        data => {
          if (data.success) {
            this.loadRegions();
            this.person.regionId = data.objects.regionId;
            if (this.changeRegion) {
              this.onChangeRegion();
            } else {
              notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, 'Create region successfully!!');
            }
            this.region = null;
            this.changeRegion = false;
            $('#modal-region').modal('hide');
          } else {
            notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
          }
        },
        error => {
          this.onIsError();
        }
      );
  }

  onChangeRegion() {
    this.leadTrainerService.changeRegionToLeadTrainer({ leadTrainerId: this.person.trainerId, regionId: this.person.regionId })
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = 'Change region successful!!';
            typeMessage = 'success';
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError();
        }
      )
  }

  onSaveSalary() {
    if (this.salary.salaryId) {
      this.salaryService.updateSalary(this.salary)
        .subscribe(
          data => {
            let msg: string;
            let typeMessage: string;
            if (data.success) {
              msg = 'Update Salary successfully.';
              typeMessage = 'success';
            } else {
              msg = 'Ooops, On error has ocurred!';
              typeMessage = 'danger';
            }
            notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
          },
          error => {
            this.onIsError();
          }
        );
    } else {
      this.salary.salaryEmployeeId = this.person.trainerId;
      this.salary.salaryAmount = 0;
      this.salary.salaryTotal = 0;
      this.salaryService.saveSalary(this.salary)
        .subscribe(
          data => {
            let msg: string;
            let typeMessage: string;
            if (data.success) {
              msg = 'Save Salary successfully.';
              typeMessage = 'success';
              this.getDetails(this.personId);
            } else {
              msg = 'Ooops, On error has ocurred!';
              typeMessage = 'danger';
            }
            notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
          },
          error => {
            this.onIsError();
          }
        );
    }
  }

  getExpertises(trainerId) {
    this.trainerService.getAllExpertisesTrainer(trainerId).subscribe(data => { this.expertises = data.response.objects }, error => this.onIsError());
  }

  getCertifications(trainerId) {
    this.trainerService.getAllCertificationsTrainer(trainerId).subscribe(data => { this.certifications = data.response.objects }, error => this.onIsError());
  }

  getGoals(clientId) {
    this.accountService.getAllGoalsByClient(clientId).subscribe(data => { this.goals = data.response.objects }, error => this.onIsError());
  }

  getPictures() {
    this.pictureService.getPicturesProfilePersonId(this.personId).subscribe(data => { this.pictures = data.response.objects }, error => this.onIsError());
  }

  getTrainersByLeadTrainer(leadTrainerId) {
    this.leadTrainerService.getTrainersLeadTrainerStatus(leadTrainerId, 1).subscribe(data => { this.trainers = data.objects; }, error => this.onIsError());
  }

  getLeadTrainers() {
    this.accountService.getPersonsByTypeUser(2).subscribe(data => { this.trainers = data.response.objects; }, error => this.onIsError());
  }

  getTrainers() {
    this.accountService.getPersonsByTypeUser(0).subscribe(data => { this.trainers = data.response.objects; this.dtTrigger.next(); }, error => this.onIsError());
  }

  updateExpertise(trainerExpertiseId, status, trainerId) {
    this.trainerService.updateExpertiseTrainer(trainerExpertiseId, status)
      .subscribe(data => {
        let msg: string;
        let object = data.response;
        this.isError = !object.success;
        if (object.success) {
          msg = object.objects[0].msg;
          this.getExpertises(trainerId);
        } else {
          msg = 'Ooops, On error has ocurred!';
        }
        notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, msg);
      }, error => this.onIsError());
  }

  updateCertification(trainerCertificationId: number, status: number, trainerId: number) {
    this.trainerService.updateCertificationTrainer(trainerCertificationId, status)
      .subscribe(data => {
        let msg: string;
        let object = data.response;
        this.isError = !object.success;
        if (object.success) {
          msg = object.objects[0].msg;
          this.getCertifications(trainerId);
        } else {
          msg = 'Ooops, On error has ocurred!';
        }
        notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, msg);
      }, error => this.onIsError());
  }

  updatePictureProfile(profilePictureId: number, status: number) {
    this.pictureService.updateProfilePicture(profilePictureId, status)
      .subscribe(
        data => {
          let msg: string;
          let object = data;
          this.isError = !object.success;
          if (object.success) {
            msg = object.objects[0].msg;
            this.getPictures();
          } else {
            msg = 'Ooops, On error has ocurred!';
          }
          notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => this.onIsError());
  }

  updateStatus(user) {
    if (user.userType == 0 && !user.salaryId) {
      notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, 'The selected trainer needs a salary. Please add salary for continue with the process.');
      return;
    }
    if (user.userType == 1 && !user.clientTrainerId) {
      notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, 'The selected client needs a trainer. Please assign trainer for continue with the process.');
      return;
    }
    if (user.userType == 1 && !user.clientPlanId) {
      notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, 'The selected client needs a plan. Please going to manage plans for add plan to client.');
      return;
    }
    this.userService.updateUserStatus(user.userId, 1)
      .subscribe(
        data => {
          let object = data.response;
          let msg: string;
          if (object.success) {
            msg = object.objects[0].msg;
            this.person.status = 1;
          } else {
            msg = 'Ooops, On error has ocurred!';
          }
          notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, msg);
        }, error => this.onIsError());
  }

  assign(typeUser: number, personId: number, trainerId: number) {
    switch (typeUser) {
      case 0: {//Trainer
        this.trainerService.assignLeadTrainerTrainer(trainerId, this.trainerId, 1)
          .subscribe(
            data => {
              let object = data.response;
              let msg: string;
              if (object.success) {
                msg = object.objects[0].msg;
              } else {
                msg = 'Ooops, On error has ocurred!';
              }
              notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, msg);
            }, error => this.onIsError());
        break;
      }
      case 1: {//Client
        this.trainerService.assignClientTrainer(personId, this.trainerId, 1)
          .subscribe(
            data => {
              let object = data.response;
              let msg: string;
              if (object.success) {
                msg = object.objects[0].msg;
              } else {
                msg = 'Ooops, On error has ocurred!';
              }
              notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, msg);
            }, error => this.onIsError());
        break;
      }
    }
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
      this.isError = false;
    }, 4000);
  }
}
