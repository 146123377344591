import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { PlanService } from '../../services/plan/plan.service';

@Component({
  selector: 'clientplans',
  templateUrl: './clientplans.component.html',
  styleUrls: ['./clientplans.component.scss']
})
export class ClientplansComponent implements OnInit {

  @Input() clientId: number;
  @Input() typeUser: number;

  @Output() detailClick: EventEmitter<any> = new EventEmitter<any>();

  plans: any = [];

  constructor(
    private planService: PlanService
  ) { }

  ngOnInit() {
    this.loadPlans();
  }

  loadPlans() {
    switch (this.typeUser) {
      case 0:
        this.planService.getClientPlansByTrainer(this.clientId).subscribe(data => { this.plans = data.objects; });
        break;
      case 1:
        this.planService.getPlansByClient(this.clientId).subscribe(data => { this.plans = data.objects; });
        break;
      case 2:
        this.planService.getClientPlansByLeadTrainer(this.clientId).subscribe(data => { this.plans = data.objects; });
        break;
    }
  }

  onClickDetails(): void {
    this.detailClick.emit();
  }

}
