import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';

import { SERVER_URL } from "../../general/general.variables";

@Injectable({
    providedIn: "root"
})
export class ChatService {

    // Our socket connection
    private socket;

    constructor() {
        this.socket = io(SERVER_URL);
    }

    joinRoom(data) {
        this.socket.emit('room', data);
    }

    sendMessage(data) {
        this.socket.emit('add-message', data);
    }

    messageReceived() {
        let observable = new Observable<any>(observer => {
            this.socket.on('messages', (data) => {
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });

        return observable;
    }

    conversationsReceived() {
        let observable = new Observable<any>(observer => {
            this.socket.on('conversations', (data) => {
                observer.next(data);
            });
            return () => { this.socket.disconnect(); }
        });

        return observable;
    }

}