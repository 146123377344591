import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AuthService } from '../../services/authorization/auth.service';
import { SessionService } from '../../services/session/session.service';
import { TrainerService } from '../../services/trainer/trainer.service';
import { LeadTrainerService } from '../../services/leadtrainer/leadtrainer.service';
import { AccountService } from '../../services/registration/registration.service';
import { RatingService } from '../../services/rating/rating.service';

import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';
import { Subject } from 'rxjs';

declare var $: any;
declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  calendarOptions: Options;
  @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;

  clients: any[];
  events: any = [];
  session: any;
  reschedule: any;
  clientTrainer: any = {};
  userType: number;
  trainerId: number;
  rating: any;

  public isError = false;

  constructor(
    public authService: AuthService,
    private sessionService: SessionService,
    private accountService: AccountService,
    private trainerService: TrainerService,
    private leadTrainerService: LeadTrainerService,
    private ratingService: RatingService,
    public datepipe: DatePipe) { }

  ngOnInit() {
    this.initDatatables();
    this.userType = this.authService.getCurrentUser()['userType'];
    this.trainerId = this.authService.getCurrentUser()['trainerId'];
    let personId = this.authService.getCurrentUser()['personId'];
    this.getSessions(this.userType, this.userType == 1 ? personId : this.trainerId);
    this.initCalendar();
    if (this.userType != 1) {
      this.getClients(this.userType, this.trainerId);
    }
    this.initSession();
    this.initReschedule();
    this.initRating();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  initDatatables() {
    this.dtOptions = {
      pagingType: 'full_numbers'
    };
  }

  initCalendar() {
    this.calendarOptions = {
      editable: false,
      eventLimit: false,
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listMonth'
      },
      events: []
    };
  }

  private initSession() {
    this.session = {
      sessionId: null,
      clientTrainerId: null,
      clientPlanId: null,
      appointmentDate: null,
      duration: null,
      sessionTypeId: 1,
      description: null,
      startTime: null
    };
  }

  private initRating() {
    this.rating = {
      ratingId: null,
      sessionId: null,
      score: 0,
      notes: null,
      recommended: null,
      useful: null
    };
  }

  private initReschedule() {
    this.reschedule = {
      sessionId: null,
      dateReschedule: null,
      duration: null,
      startTime: null,
      latitude: null,
      longitude: null
    };
  }

  getClients(userType: number, trainerId: number) {
    switch (userType) {
      case 0:
        this.trainerService.getClientsTrainer(trainerId, 1).subscribe(data => { this.clients = data.objects; this.dtTrigger.next(); }, error => this.onIsError());
        break;
      case 2:
        this.leadTrainerService.getClientsLeadTrainerStatus(trainerId, 1).subscribe(data => { this.clients = data.objects; this.dtTrigger.next(); }, error => this.onIsError());
        break;
      case 3:
        this.accountService.getPersonsByTypeUser(1).subscribe(data => { this.clients = data.response.objects; this.dtTrigger.next(); }, error => this.onIsError());
        break;
    }
  }

  getSessions(userType: number, id: number) {
    switch (userType) {
      case 0:
        this.sessionService.getAllSessionsByTrainer(id)
          .subscribe(
            data => {
              let objects = data.objects;
              let events = [];
              objects.forEach(function (object) {
                let event = {
                  'id': object.sessionId,
                  'title': object.client,
                  'start': object.appointmentDate + 'T' + object.startTime + ':00',
                  'className': ''
                };
                switch (object.status) {
                  case 1:
                    event.className = 'badge-primary';
                    break;
                  case 2:
                    event.className = 'badge-primary';
                    break;
                  case 3:
                    event.className = 'badge-warning';
                    break;
                  case 4:
                    event.className = 'badge-light';
                    break;
                  case 5:
                    event.className = 'badge-danger';
                    break;
                  case 6:
                    event.className = 'badge-warning';
                    break;
                  case 7:
                    event.className = 'badge-success';
                    break;
                  case 8:
                    event.className = 'badge-secondary';
                    break;
                  case 9:
                    event.className = 'badge-danger';
                    break;
                  case 10:
                    event.className = 'badge-warning';
                    break;
                  case 11:
                    event.className = 'badge-success';
                    break;
                  case 12:
                    event.className = 'badge-warning';
                    break;
                }
                events.push(event);
              });
              this.events = events;
            },
            error => this.onIsError());
        break;
      case 1:
        this.sessionService.getAllSessionsByClient(id)
          .subscribe(
            data => {
              let objects = data.objects;
              let events = [];
              objects.forEach(function (object) {
                let event = {
                  'id': object.sessionId,
                  'title': object.description,
                  'start': object.appointmentDate + 'T' + object.startTime + ':00',
                  'className': ''
                };
                switch (object.status) {
                  case 1:
                    event.className = 'badge-primary';
                    break;
                  case 2:
                    event.className = 'badge-primary';
                    break;
                  case 3:
                    event.className = 'badge-warning';
                    break;
                  case 4:
                    event.className = 'badge-light';
                    break;
                  case 5:
                    event.className = 'badge-danger';
                    break;
                  case 6:
                    event.className = 'badge-warning';
                    break;
                  case 7:
                    event.className = 'badge-success';
                    break;
                  case 8:
                    event.className = 'badge-secondary';
                    break;
                  case 9:
                    event.className = 'badge-danger';
                    break;
                  case 10:
                    event.className = 'badge-warning';
                    break;
                  case 11:
                    event.className = 'badge-success';
                    break;
                  case 12:
                    event.className = 'badge-warning';
                    break;
                }
                events.push(event);
              });
              this.events = events;
            }, error => this.onIsError());
        break;
      case 2:
        this.sessionService.getAllSessionsByLeadTrainer(id)
          .subscribe(
            data => {
              let objects = data.objects;
              let events = [];
              objects.forEach(function (object) {
                let event = {
                  'id': object.sessionId,
                  'title': object.client,
                  'start': object.appointmentDate + 'T' + object.startTime + ':00',
                  'className': ''
                };
                switch (object.status) {
                  case 1:
                    event.className = 'badge-primary';
                    break;
                  case 2:
                    event.className = 'badge-primary';
                    break;
                  case 3:
                    event.className = 'badge-warning';
                    break;
                  case 4:
                    event.className = 'badge-light';
                    break;
                  case 5:
                    event.className = 'badge-danger';
                    break;
                  case 6:
                    event.className = 'badge-warning';
                    break;
                  case 7:
                    event.className = 'badge-success';
                    break;
                  case 8:
                    event.className = 'badge-secondary';
                    break;
                  case 9:
                    event.className = 'badge-danger';
                    break;
                  case 10:
                    event.className = 'badge-warning';
                    break;
                  case 11:
                    event.className = 'badge-success';
                    break;
                  case 12:
                    event.className = 'badge-warning';
                    break;
                }
                events.push(event);
              });
              this.events = events;
            }, error => this.onIsError());
        break;
    }
  }

  selectClient(clientTrainer: any) {
    this.clientTrainer = clientTrainer;
    this.getSessions(1, this.clientTrainer.personId);
  }

  dayClick(detail: any) {
    if (this.userType == 0) {
      let selectDateString = detail.date.format('MM-DD-YYYY');
      let selectDate = new Date(selectDateString);
      let currentDate = new Date();

      if (selectDate <= currentDate) {
        notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, 'Please select a date after today.');
        return;
      }
      if (this.clientTrainer.clientTrainerId) {
        if (this.clientTrainer.status == 5) {
          notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, `The last payment was unsuccessful.`);
          return;
        }
        if (!this.clientTrainer.clientPlanId) {
          notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, `The selected client doesn't have a assigned plan.`);
          return;
        }
        $('#modal-session').modal('show');
        this.initSession();
        this.session.clientPlanId = this.clientTrainer.clientPlanId;
        this.session.clientTrainerId = this.clientTrainer.clientTrainerId;
        this.session.appointmentDate = selectDateString;
      } else {
        notify(null, 'warning', 'bounceInRight', 'bounceOutRight', null, 'Please, select a client!');
      }
    }
  }

  eventClick(model: any) {
    let sessionId = model.event.id;
    this.sessionService.getSessionById(sessionId)
      .subscribe(
        data => {
          if (data.success) {
            this.session = data.objects;
            this.session.appointmentDate = this.datepipe.transform(this.session.appointmentDate, 'MM-dd-yyyy');
            if (!this.clientTrainer.clientTrainerId) {
              let clientTrainer = this.clients.filter((element) => { return (element.clientTrainerId == this.session.clientTrainerId); });
              if (clientTrainer.length > 0) {
                this.clientTrainer = clientTrainer[0];
              }
            }
            if (this.userType != 1) {
              $('#modal-session').modal('show');
            } else {
              $('#modal-rating').modal('show');
              this.getRatingSession(sessionId);
            }
          }
        }, error => { this.onIsError(); }
      );
  }

  getRatingSession(sessionId) {
    this.ratingService.getRatingBySession(sessionId)
      .subscribe(
        data => {
          this.rating = null;
          if (data.objects) {
            this.rating = data.objects;
          } else {
            this.initRating();
            this.rating.sessionId = sessionId;
          }
        },
        error => {
          this.onIsError();
        });
  }

  onClick(rating: number): void {
    this.rating.score = rating;
  }

  showReschedule() {
    $('#modal-session').modal('hide');
    $('#modal-reschedule').modal('show');
  }

  saveRating() {
    // console.log(this.rating);
    this.rating.useful = this.rating.useful ? 1 : 0;
    this.rating.recommended = this.rating.recommended ? 1 : 0;
    this.ratingService.saveRating(this.rating)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects.msg;
            typeMessage = 'success';
            this.rating = null;
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
          $('#modal-rating').modal('hide');
        },
        error => {
          this.onIsError()
        }
      );
  }

  onSave() {
    this.session.appointmentDate = this.datepipe.transform(this.session.appointmentDate, 'yyyy-MM-dd');
    this.sessionService.saveSession(this.session)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects.msg;
            typeMessage = 'success';
            this.getSessions(1, this.clientTrainer.personId);
            this.session = null;
            this.initSession();
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
          $('#modal-session').modal('hide');
        }, error => this.onIsError()
      );
  }

  saveReschedule() {
    this.reschedule.sessionId = this.session.sessionId;
    this.sessionService.saveReschedule(this.reschedule)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects.msg;
            typeMessage = 'success';
            this.getSessions(1, this.clientTrainer.personId);
            this.session = null;
            this.reschedule = null;
            this.initSession();
            this.initReschedule();
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
          $('#modal-reschedule').modal('hide');
        }, error => { this.onIsError(); });
  }

  onCheckOut() {
    let rawSession = { sessionId: this.session.sessionId, status: 11 };
    this.sessionService.checkOut(rawSession)
      .subscribe(
        data => {
          let personId = this.authService.getCurrentUser()['personId'];
          this.getSessions(this.userType, this.userType == 1 ? personId : this.trainerId);
          notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, data.msg);
        }, error => this.onIsError()
      );
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
      this.isError = false;
    }, 4000);
  }

}
