import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthService } from '../../services/authorization/auth.service';
import { TrainerService } from '../../services/trainer/trainer.service';
import { LeadTrainerService } from '../../services/leadtrainer/leadtrainer.service';
import { AccountService } from '../../services/registration/registration.service';
import { SessionService } from '../../services/session/session.service';

import { SERVER_URL } from "../../general/general.variables";
import { PlanService } from 'src/app/services/plan/plan.service';

declare var $: any;
declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnDestroy, OnInit {

  dtOptions: DataTables.Settings[] = [];
  dtTrigger01: Subject<any> = new Subject();
  dtTrigger02: Subject<any> = new Subject();
  dtTrigger03: Subject<any> = new Subject();

  public serverUrl: string;
  public expertises: [];
  public certifications: [];
  public expertisesTrainer: [];
  public certificationsTrainer: [];
  public goals: [];
  public clients: [];
  public trainers: [];
  public sessions: [];
  public measurements: any[] = [
    { sessionId: 1, title: 'Measurements 1', measurement: '1' },
    { sessionId: 2, title: 'Measurements 2', measurement: '2' },
    { sessionId: 3, title: 'Measurements 3', measurement: '3' },
    { sessionId: 4, title: 'Measurements 4', measurement: '4' },
    { sessionId: 5, title: 'Measurements 5', measurement: '5' }
  ];

  public isError = false;
  public client: any = null;

  constructor(
    public authService: AuthService,
    private accountService: AccountService,
    private trainerService: TrainerService,
    private leadTrainerService: LeadTrainerService,
    private sessionService: SessionService,
    private planService: PlanService
  ) { }

  ngOnInit() {
    this.initDatatables();
    this.serverUrl = SERVER_URL;
    let userType: number = this.authService.getCurrentUser()['userType'];
    if (userType != 1) {
      let trainerId = this.authService.getCurrentUser()['trainerId'];
      this.getExpertises(trainerId);
      this.getCertifications(trainerId);
      this.getClients(userType, trainerId);
      this.getTrainers(userType, trainerId);
      this.getSessionsByTrainer(userType, trainerId);
    } else {
      let personId = this.authService.getCurrentUser()['personId'];
      this.getGoals(personId);
      this.getSessionsByClient(personId);
    }
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger01.unsubscribe();
    this.dtTrigger02.unsubscribe();
    this.dtTrigger03.unsubscribe();
  }

  initDatatables() {
    this.dtOptions[0] = {
      pagingType: 'full_numbers'
    };
    this.dtOptions[1] = {
      pagingType: 'full_numbers'
    };
    this.dtOptions[2] = {
      pagingType: 'full_numbers'
    };
  }

  viewDetailsClient(client: any) {
    this.client = client;
    if (this.client.trainerLeadTrainerId) {
      this.getExpertisesTrainer(this.client.trainerId);
      this.getCertificationsTrainer(this.client.trainerId);
    } else {
      this.getGoals(client.personId);
    }
  }

  closeDetailClient() {
    this.client = null;
    this.goals = null;
  }

  chargeManually(client: any) {
    this.planService.chargeToClient({ clientPlanId: client.clientPlanId })
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.objects;
            typeMessage = 'success';
            this.client.status = 1;
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => this.onIsError());
  }

  getExpertisesTrainer(trainerId: number) {
    this.trainerService.getExpertisesStatusTrainer(trainerId, 1).subscribe(data => { this.expertisesTrainer = data.response.objects; }, error => this.onIsError());
  }

  getCertificationsTrainer(trainerId: number) {
    this.trainerService.getCertificationsStatusTrainer(trainerId, 1).subscribe(data => { this.certificationsTrainer = data.response.objects; }, error => this.onIsError());
  }

  getExpertises(trainerId: number) {
    this.trainerService.getExpertisesStatusTrainer(trainerId, 1).subscribe(data => { this.expertises = data.response.objects; }, error => this.onIsError());
  }

  getCertifications(trainerId: number) {
    this.trainerService.getCertificationsStatusTrainer(trainerId, 1).subscribe(data => { this.certifications = data.response.objects; }, error => this.onIsError());
  }

  getClients(userType: number, trainerId: number) {
    switch (userType) {
      case 0:
        this.trainerService.getClientsTrainer(trainerId, 1).subscribe(data => { this.clients = data.objects; this.dtTrigger02.next(); }, error => this.onIsError());
        break;
      case 2:
        this.leadTrainerService.getClientsLeadTrainerStatus(trainerId, 1).subscribe(data => { this.clients = data.objects; this.dtTrigger02.next(); }, error => this.onIsError());
        break;
      case 3:
        this.accountService.getPersonsByTypeUser(1).subscribe(data => { this.clients = data.response.objects; this.dtTrigger02.next(); }, error => this.onIsError());
        break;
    }
  }

  getTrainers(userType: number, trainerId: number) {
    switch (userType) {
      case 2:
        this.leadTrainerService.getTrainersLeadTrainerStatus(trainerId, 1).subscribe(data => { this.trainers = data.objects; this.dtTrigger01.next(); }, error => this.onIsError());
        break;
      case 3:
        this.accountService.getPersonsByTypeUser(0).subscribe(data => { this.trainers = data.response.objects; this.dtTrigger01.next(); }, error => this.onIsError());
        break;
    }
  }

  getSessionsByClient(clientId: number) {
    this.sessionService.getAllSessionsByClient(clientId).subscribe(data => { this.sessions = data.objects; this.dtTrigger03.next(); }, error => this.onIsError());
  }

  getSessionsByTrainer(userType: number, trainerId: number) {
    switch (userType) {
      case 0:
        this.sessionService.getAllSessionsByTrainer(trainerId).subscribe(data => { this.sessions = data.objects; this.dtTrigger03.next(); }, error => this.onIsError());
        break;
      case 2:
        this.sessionService.getAllSessionsByLeadTrainer(trainerId).subscribe(data => { this.sessions = data.objects; this.dtTrigger03.next(); }, error => this.onIsError());
        break;
    }
  }

  getGoals(clientId: number) {
    this.accountService.getAllGoalsByClient(clientId).subscribe(data => { this.goals = data.response.objects; }, error => this.onIsError());
  }

  hideDialogClientDetail(): void {
    $('#modal-keyboard').modal('hide');
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      //notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
      this.isError = false;
    }, 4000);
  }

}