import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from '../../services/authorization/auth.service';

declare function notify(icon, type, animIn, animOut, title, message): any;
declare var $: any;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public user: any;
  public isError = false;
  helper = new JwtHelperService();
  typeUser: number;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    this.user = {
      'userName': '',
      'password': '',
      'email': ''
    };
    if (this.authService.getCurrentUser()) {
      this.router.navigate(['/profile']);
    }
  }

  ngOnInit() {
    let success = this.route.snapshot.params['success'];
    if (success != undefined) {
      if (JSON.parse(success) == true) {
        notify(null, 'success', 'bounceInRight', 'bounceOutRight', null, 'Account successfully created.');
      }
    }
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('background-login');
  }

  ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('background-login');
  }

  onLogin(): void {
    this.authService.login(this.user.userName, this.user.password)
      .subscribe(
        data => {
          let object = data.response;
          this.isError = !object.success;
          if (object.success) {
            if (object.objects != -1) {
              const decodedToken = this.helper.decodeToken(object.objects);
              let user = decodedToken.user;

              this.authService.setToken(object.objects);
              this.authService.setUser(user);
              this.router.navigate(['/profile']);
            } else {
              this.user.password = '';
              notify(null, 'warning', 'rotateIn', 'rotateOut', null, object.message);
            }
          } else {
            notify(null, 'warning', 'rotateIn', 'rotateOut', null, 'On error has ocurred.');
          }
        },
        error => this.onIsError()
      );
  }

  sendPasswordCode() {
    this.authService.sendPasswordCode(this.user.email)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = data.message;
            typeMessage = 'success';
          } else {
            msg = data.objects.msg;
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => this.onIsError()
      );
  }

  verifyCode() {
    this.authService.verifyCode(this.user.code)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            if (data.objects.status == 1) {
              typeMessage = 'success';
            } else {
              typeMessage = 'danger';
            }
            msg = data.objects.msg;
          } else {
            typeMessage = 'danger';
            msg = 'Ooops, On error has ocurred!';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => this.onIsError()
      )
  }

  changePassword() {
    this.authService.changePassword({ code: this.user.code, password: this.user.password })
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            typeMessage = 'success';
            msg = data.message;
            this.user.password = null;
            this.user.code = null;
            this.user.email = null;
          } else {
            typeMessage = 'danger';
            msg = data.objects.msg;
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => this.onIsError()
      )
  }

  showModal(typeUser: number) {
    this.typeUser = typeUser;
    $('#modal-code').modal('show');
  }

  goToRegister() {
    $('#modal-code').modal('hide');
    this.router.navigate(['/account'], { queryParams: { typeUser: this.typeUser, codeInvitation: this.user.invitationCode } });
  }

  onIsError(): void {
    this.isError = true;
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
    }, 4000);
  }

}
