import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SERVER_URL } from 'src/app/general/general.variables';
import { AuthService } from '../authorization/auth.service';
import { Observable } from 'rxjs';
import { ResponseInterface } from 'src/app/models/authorization/response-interface';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private urlBase: string;
  private headers: HttpHeaders;

  constructor(public _http: HttpClient, private auth: AuthService) {
    this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
    this.urlBase = SERVER_URL + 'notifications';
  }

  sendNotification(notification: Object): Observable<any> {
    return this._http.post<ResponseInterface>(this.urlBase, notification, { headers: this.headers }).pipe(map(data => data));
  }
}
