import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SalaryService } from 'src/app/services/salary/salary.service';
import { SERVER_URL } from 'src/app/general/general.variables';

declare function notify(icon, type, animIn, animOut, title, message): any;

@Component({
  selector: 'detail-salary',
  templateUrl: './detail-salary.component.html',
  styleUrls: ['./detail-salary.component.scss']
})
export class DetailSalaryComponent implements OnInit {

  salaryId;
  salary: any;

  constructor(
    private route: ActivatedRoute,
    private salaryService: SalaryService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.salaryId = params['salaryId'];
      if (this.salaryId) {
        this.salaryService.getSalaryById(this.salaryId)
          .subscribe(
            data => {
              this.salary = data.objects[0];
              if (this.salary) {
                if (this.salary['pictureProfile']) {
                  this.salary['pictureProfile'] = SERVER_URL + this.salary['pictureProfile'];
                }
              }
            }
          );
      }
    });
  }

  onChange() {
    this.salary.perSessionFixedPrice = 0;
    this.salary.perSessionPercent = 0;
  }

  onUpdateSalary() {
    this.salaryService.updateSalary(this.salary)
      .subscribe(
        data => {
          let msg: string;
          let typeMessage: string;
          if (data.success) {
            msg = 'Update Salary successfully.';
            typeMessage = 'success';
          } else {
            msg = 'Ooops, On error has ocurred!';
            typeMessage = 'danger';
          }
          notify(null, typeMessage, 'bounceInRight', 'bounceOutRight', null, msg);
        },
        error => {
          this.onIsError();
        }
      );
  }

  onIsError(): void {
    setTimeout(() => {
      notify(null, 'danger', 'bounceInRight', 'bounceOutRight', null, 'Ooops, On error has ocurred!');
    }, 4000);
  }

}
