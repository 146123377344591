import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ResponseInterface } from "../../models/authorization/response-interface";
import { SERVER_URL } from "../../general/general.variables";

import { AuthService } from "../authorization/auth.service"

@Injectable({
    providedIn: "root"
})
export class UserService {
    private urlBase: string;
    private headers: HttpHeaders;

    constructor(public _http: HttpClient, private auth: AuthService) {
        this.headers = new HttpHeaders({ "Content-Type": "application/json", 'Authorization': `Bearer ${this.auth.getTokenString()}` });
        this.urlBase = SERVER_URL + 'users/';
    }

    updateUserStatus(userId: number, status: number): Observable<any> {
        return this._http.put<ResponseInterface>(this.urlBase, {userId, status}, { headers: this.headers }).pipe(map(data => data));
    }
}
